/* eslint-disable react/no-danger-with-children */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState, useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from "react-router-dom";
import { CoreContext } from '../context/core-context';
import { AuthContext } from '../context/auth-context';
import { useHistory } from 'react-router-dom';
import { XSquare } from 'react-bootstrap-icons';
import { Badge, Form } from 'react-bootstrap';
import Input from './common/Input';
import ImageUploader from './common/UploadImage';


const Client = (props) => {
    const history = useHistory();


    const coreContext = useContext(CoreContext);
    const authContext = useContext(AuthContext);

    const [name, setName] = useState('');
    const [contactName, setContactName] = useState('');
    const [tag, setTag] = useState('');
    const [loc, setLOC] = useState('');
    const [email, setEmail] = useState('');
    const [contact1, setContact1] = useState('');
    const [contact2, setContact2] = useState();
    const [contact, setContact] = useState();
    const [address, setAddress] = useState();
    const [description, setDescription] = useState();

    const [pincode, setPincode] = useState();

    const [localArea, setLocalArea] = useState('');

    const [stateId, setStateId] = useState('');
    const [districtId, setDistrictId] = useState('');
    const [category, setCategory] = useState('');
    const [countryId, setCountryId] = useState('');
    const [erpBranchid, setErpBranchid] = useState('');
    const [isCollege, setIsCollege] = useState('no');
    const [hasImages, setHasImages] = useState(false);
    const [hideMenu, setHideMenu] = useState('no');
    const [hideFooter, setHideFooter] = useState('no');
    const [images, setImages] = useState([]);
    const [certificate, setCertificate] = useState('');
    const [videos, setVideos] = useState([]);

    const [status, setStatus] = useState('');
    const [siteUrl, setSiteURL] = useState('');
    const [nationality, setNationality] = useState('Indian');
    const [sCategory, setSCategory] = useState('Faculty');
    const [organization, setOrganization] = useState('');
    const [designation, setDesignation] = useState('');
    const [accomodation, setAccomodation] = useState('yes');
    const [presenting, setPresenting] = useState('yes');
    const [accompanying, setAccompanying] = useState('yes');
    const [paymentConfirmed, setPaymentConfirmed] = useState('no');
    const [abstractApproved, setAbstractApproved] = useState('no');

    const [nationalityOptions] = useState([{ name: 'Indian', value: 'Indian' }, { name: 'Others', value: 'Others' }]);
    const [yesNoOptions] = useState([{ name: 'Yes', value: 'yes' }, { name: 'No', value: 'no' }]);
    const [sRoleOptions] = useState([{ name: 'All', value: '' },{ name: 'Faculty from BHU', value: 'Faculty' }, { name: 'Student from BHU', value: 'Student' }, { name: 'Post Docs from BHU', value: 'Post Docs' },{ name: 'Faculty from Other Institute', value: 'Faculty from Other Institute' }, { name: 'Student  from Other Institute', value: 'Student from Other Institute' }, { name: 'Post Docs from Other Institute', value: 'Post Docs from Other Institute' },{ name: 'Overseas Faculty', value: 'Overseas Faculty' }, { name: 'Overseas Student', value: 'Overseas Student' }, { name: 'Overseas Post Docs', value: 'Oversease Post Docs' }, { name: 'Industrial Participants', value: 'Industrial Participants' }]);

    const [presentingOptions] = useState([{ name: 'Select One', value: '' }, { name: 'Invited Talk', value: 'Invited Talk' }, { name: 'Contributed Talk', value: 'Contributed Talk' }, { name: 'Poster Presentation', value: 'Poster Presentation' }, { name: 'Only Participating', value: 'Only Participating' }]);

    const [accompanyingOptions] = useState([{ name: 'None', value: 'no' }, { name: 'One', value: '1' }, { name: 'Two', value: '2' }, { name: 'Three', value: '3' }, { name: 'Four', value: '4' }, { name: 'Five', value: '5' }, { name: 'Six', value: '6' }, { name: 'Seven', value: '7' }, { name: 'Eight', value: '8' }, { name: 'Nine', value: '9' }, { name: 'Ten', value: '10' },]);


    const [selectedImages, setSelectedImages] = useState([]);
    const [uploadButtonDisabled, setUploadButtonDisabled] = useState(true);

    //useEffect(setClient(coreContext.client), [client]);

    const fetchClient = () => {

        //this.props.match.params.id //accessing param
       // console.log('paramas', props.match.params.id);
        //console.log('paramas', props.location);
        coreContext.fetchClient(props.location, authContext.ownerId);
    }

    useEffect(fetchClient, []);


    const loadLoactionOptions = () => {
        coreContext.fetchCountries();
        if (coreContext.client.countryId)
            coreContext.fetchStates(coreContext.client.countryId);
        if (coreContext.client.stateId)
            coreContext.fetchDistricts(coreContext.client.countryId, coreContext.client.stateId);
        if (coreContext.client.id)
            coreContext.fetchLocalAreas(coreContext.client.id);
        else history.push('/applicants');
    }

    const onImagesUploadHandler = async () => {
        const count = selectedImages.length;
        if (count > 1) { alert('Only one pdf should be uploaded for this section !'); return; }
        let counter = 0;

        for await (const image of selectedImages) {
            ++counter;
            const formData = new FormData();
            formData.append('itemId', props.match.params.id);
            formData.append('image', image, image.name);
            formData.append('userId', authContext.userId);
            coreContext.uploadProfileImage(formData, coreContext.client.id);

            if (counter === count) {
                setTimeout(() => {
                    setSelectedImages([]);

                }, 2000);

                setTimeout(() => {
                    history.push('/profile');
                }, 5000);
            };
        }
    }


    const onImagesSelected = (pictures) => {
        setSelectedImages(pictures);
        //  console.log(pictures);
        if (pictures.length > 0) setUploadButtonDisabled(false); else setUploadButtonDisabled(true);
    }



    const populateClient = () => {
        console.log(coreContext.client);
        setName(coreContext.client.item_name);
        setContactName(coreContext.client.item_contact_person);
        setTag(coreContext.client.item_tag);
        setLOC(coreContext.client.item_loc);
        setEmail(coreContext.client.item_email);
        setErpBranchid(coreContext.client.erp_branchid);
        setIsCollege(coreContext.client.is_college);
        setContact1(coreContext.client.item_mobile_1);
        setContact2(coreContext.client.item_mobile_2);
        setContact(coreContext.client.item_contact);
        setAddress(coreContext.client.item_address);
        setDescription(coreContext.client.item_description);
        setPincode(coreContext.client.item_pincode);
        setLocalArea(coreContext.client.areaId);
        setStateId(coreContext.client.stateId);
        setDistrictId(coreContext.client.districtId);
        setCategory(coreContext.client.categoryId);
        setCountryId(coreContext.client.countryId);
        // if(coreContext.client.enroll_today_image)
        setVideos([coreContext.client.enroll_today_image]);
        // else window.location.href = '/applicant';
        //  if(coreContext.client.register_now_image)
        setImages([coreContext.client.register_now_image]);
        setCertificate(coreContext.client.profile_image);
        // else window.location.href = '/applicant';
        setStatus(coreContext.client.item_status);
        setSiteURL(coreContext.client.site_url);
        setHideMenu(coreContext.client.hide_menu);
        setHideFooter(coreContext.client.hide_footer);

        setNationality(coreContext.client.smst_nationality);
        setSCategory(coreContext.client.smst_category);
        setOrganization(coreContext.client.smst_organization);
        setDesignation(coreContext.client.smst_designation);
        setAccomodation(coreContext.client.smst_accomodation);
        setPresenting(coreContext.client.smst_presenting);
        setAccompanying(coreContext.client.smst_accompanying_person);
        setPaymentConfirmed(coreContext.client.smst_booking_confirmed);
        setAbstractApproved(coreContext.client.smst_abstract_accepted);


    }

    useEffect(loadLoactionOptions, [coreContext.client]);
    useEffect(populateClient, [coreContext.client]);

    const renderCertificate = () => {
       
        if (certificate)
            return <div className="col-md-6"> <a rel="noopener noreferrer" target="_blank" href={`https://${certificate}`}><img style={{ padding: 5 }} className="img-fluid" src={'https://node-websites.siddhantait.com/images/pdfimage.png'} alt='Certificate' /></a></div>

            return <div className="col-md-6"><img style={{ padding: 5 }} className="img-fluid" src='https://node-websites.siddhantait.com/images/file-not-found-icon-png.png' alt='Certificate' /></div>
    }

    const renderImages = () => {
        if (images.length > 0)
            return images.map(image => <a rel="noopener noreferrer" target="_blank" href={`https://${image}`}><img style={{ padding: 5 }} className="img-fluid" src={image.includes('.pdf') ? 'https://node-websites.siddhantait.com/images/pdfimage.png' : image.includes('.docs') ? 'https://node-websites.siddhantait.com/images/word-file.jpg' : image.includes('.docx') ? 'https://node-websites.siddhantait.com/images/word-file.jpg' : 'https://node-websites.siddhantait.com/images/file-not-found-icon-png.png'} alt='Abstract not found' /></a>)
    }

    const renderVideos = () => {
        //console.log(coreContext.client);
        if (videos.length > 0)
            return videos.map(image => <a rel="noopener noreferrer" target="_blank" href={`https://${image}`}><img style={{ padding: 5 }} className="img-fluid" src={image.includes('.pdf') ? 'https://node-websites.siddhantait.com/images/pdfimage.png' : image.includes('.docs') ? 'https://node-websites.siddhantait.com/images/word-file.jpg' : image.includes('.docx') ? 'https://node-websites.siddhantait.com/images/word-file.jpg' : 'https://node-websites.siddhantait.com/images/file-not-found-icon-png.png'} alt='Payment receipt not found' /></a>)
    }


    const { register, handleSubmit, errors } = useForm({
        mode: 'onSubmit',
        reValidateMode: 'onBlur'
    });


    const clientEditHandler = (data) => {
        coreContext.editClient(coreContext.client.id, authContext.ownerId, name, email, address, contact1, contact2, contactName, tag, loc, contact, description, pincode, localArea, stateId, districtId, category, countryId, status, siteUrl, hideMenu, hideFooter, erpBranchid, isCollege, nationality, sCategory, organization, designation, accomodation, presenting, accompanying, paymentConfirmed, abstractApproved);

    }


    const onEmailChangedHandler = (e) => {
        setEmail(e.target.value);
    }

    const onContact1Handler = (e) => {
        setContact1(e.target.value);
    }


    const onNameChangedHandler = (e) => {
        setName(e.target.value);
    }




    return (<div className='container'>
        <div className="row" >
            <div className="col-md-6">
                <div className="card">
                    <div className="card-title mx-auto"><h3><Badge variant="success">Modify Applicant Details</Badge></h3></div>
                    <div className="card-body">
                        <Form autoComplete='off' onSubmit={handleSubmit(clientEditHandler)} noValidate>
                            <Input label='Name' value={name} name='name' minLength={5} maxLength={55} required={true} register={register} errors={errors} elementType='text' placeholder='Enter Name' onChange={onNameChangedHandler} />

                            <Input label='Email' name='email' value={email} required={true} register={register} errors={errors} elementType='text' readOnly={true} minLength={5} maxLength={55} pattern={/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/} placeholder='Enter Email' onChange={onEmailChangedHandler} />

                            <div className="row" >
                                <div className="col-md-8">
                                    <Input label='Nationality' name='nationality' required={true} register={register} errors={errors} elementType='select' value={nationality} options={nationalityOptions} onChange={(e) => setNationality(e.target.value)} />
                                </div>
                                <div className="col-md-4"> <Link className="btn btn-link" to={`/reset-password/${coreContext.client.item_email}`}>Reset Password</Link></div>
                            </div>
                            <Input label='Category' name='category' required={true} register={register} value={sCategory} errors={errors} elementType='select' options={sRoleOptions} onChange={(e) => setSCategory(e.target.value)} />

                            <Input label='Institute / Organization' name='organization' minLength={2} maxLength={55} required={true} value={organization} register={register} errors={errors} elementType='text' placeholder='Enter Institute / Organization name' onChange={(e) => setOrganization(e.target.value)} />

                            <Input label='Designation' name='designation' value={designation} minLength={2} maxLength={55} required={true} register={register} errors={errors} elementType='text' placeholder='Enter designation' onChange={(e) => setDesignation(e.target.value)} />

                            <Input label='Phone' name='contact1' value={contact} minLength={6} maxLength={15} required={true} register={register} errors={errors} elementType='text' placeholder='Enter Phone no.' onChange={onContact1Handler} />

                            <Input label='Accomodation Required' value={accomodation} name='accomodation' required={true} register={register} errors={errors} elementType='select' options={yesNoOptions} onChange={(e) => setAccomodation(e.target.value)} />

                            <Input label='Presenting' name='presenting' value={presenting} required={true} register={register} errors={errors} elementType='select' options={presentingOptions} onChange={(e) => setPresenting(e.target.value)} />

                            <Input label='Accompanying' name='accompanying' value={accompanying} required={true} register={register} errors={errors} elementType='select' options={accompanyingOptions} onChange={(e) => setAccompanying(e.target.value)} />

                            <Input label='Payment Confirmed' name='pconfirmed' value={paymentConfirmed} required={true} register={register} errors={errors} elementType='select' options={yesNoOptions} onChange={(e) => setPaymentConfirmed(e.target.value)} />

                            <Input label='Abstract Approved' name='aApproved' value={abstractApproved} required={true} register={register} errors={errors} elementType='select' options={yesNoOptions} onChange={(e) => setAbstractApproved(e.target.value)} />

                            <Input elementType='button' variant='primary' value="Update" /> &nbsp;&nbsp; {coreContext.renderLoader()}
                            <Input variant='danger' label={coreContext.message} elementType='label' />
                        </Form>
                    </div>
                </div>

                <div className="card">
                    <div className="card-title mx-auto"><h3><Badge variant="success">Upload Certificate </Badge></h3></div>
                    <div className="card-title mx-auto"></div>
                    <div className="card-body">
                        <ImageUploader onImagesSelected={onImagesSelected} maxWidthOrHeight='1920' pictures={selectedImages} />
                        <Input value='Upload PDF' onClick={onImagesUploadHandler} disabled={uploadButtonDisabled} elementType='button' variant='primary' />&nbsp;&nbsp; {coreContext.renderLoader()}
                        <Input variant='danger' label={coreContext.message} elementType='label' />
                    </div>
                    <div className="card-body">
                        <div className="card-title mx-auto"><h3><Badge variant="success">Applicant's Certificate</Badge></h3></div>
                        <div className="row">
                            {renderCertificate()}
                        </div>
                    </div>
                </div>


            </div>
            <div className="col-md-6" style={{ maxHeight: '500px', overflowY: 'auto', position: 'sticky', top: '50px' }}>
                <div className="card">
                    <div className="card-header">Abstract</div>
                    <div className="card-body">
                        <div className="row">
                            {renderImages()}
                        </div>
                    </div>
                </div>

                <div className="card">
                    <div className="card">
                        <div className="card-header">Payment Receipt</div>
                        <div className="card-body">
                            <div className="row">
                                {renderVideos()}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> </div>);
}


export { Client };