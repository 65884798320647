/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect, useState } from 'react';
import { Badge } from 'react-bootstrap';
import { useForm } from "react-hook-form";
import Input from './common/Input';
import { Eye } from 'react-bootstrap-icons';
import { XSquare } from 'react-bootstrap-icons';
import { CoreContext } from '../context/core-context';
import { AuthContext } from '../context/auth-context';
import { Link } from 'react-router-dom';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';


const Clients = (props) => {

    const coreContext = useContext(CoreContext);
    const authContext = useContext(AuthContext);

    const { register, handleSubmit, errors } = useForm({
        mode: 'onSubmit',
        reValidateMode: 'onBlur',
    });

    const [filter, setFilter] = useState('');
    const [filterp, setFilterP] = useState('');
    const [category, setCategory] = useState('');
    const [organization, setOrganization] = useState('');

    const [filterOptions] = useState([{ name: 'All', value: '' }, { name: 'Fee Paid', value: 'fee-paid' }, { name: 'Payment Confirmed', value: 'fee-confirmed' }, { name: 'Abstract Submitted', value: 'abstract-submitted' }, { name: 'Abstract Approved', value: 'abstract-approved' }]);

    const [sRoleOptions] = useState([{ name: 'All', value: '' },{ name: 'Faculty from BHU', value: 'Faculty' }, { name: 'Student from BHU', value: 'Student' }, { name: 'Post Docs from BHU', value: 'Post Docs' },{ name: 'Faculty from Other Institute', value: 'Faculty from Other Institute' }, { name: 'Student  from Other Institute', value: 'Student from Other Institute' }, { name: 'Post Docs from Other Institute', value: 'Post Docs from Other Institute' },{ name: 'Overseas Faculty', value: 'Overseas Faculty' }, { name: 'Overseas Student', value: 'Overseas Student' }, { name: 'Overseas Post Docs', value: 'Oversease Post Docs' }, { name: 'Industrial Participants', value: 'Industrial Participants' }]);

    const [presentingOptions] = useState([{ name: 'Select One', value: '' }, { name: 'Invited Talk', value: 'Invited Talk' },{ name: 'Contributed Talk', value: 'Contributed Talk' },{ name: 'Poster Presentation', value: 'Poster Presentation' },{ name: 'Only Participating', value: 'Only Participating' }]);

    const fetchClients = () => {
        if (authContext.item.smst_category === 'Admin')
            coreContext.fetchClients(authContext.ownerId);
    }

    useEffect(fetchClients, []);

    const changeFilter = (fil) => {
        setFilter(fil);

        let fClients = [];
        if (fil === '') fClients = coreContext.clients;
        else if (fil === 'fee-paid') {
            fClients = coreContext.clients.filter(cc => cc.smst_payment_done === 'yes');
        }
        else if (fil === 'fee-confirmed') {
            fClients = coreContext.clients.filter(cc => cc.smst_booking_confirmed === 'yes');
        }
        else if (fil === 'abstract-submitted') {
            fClients = coreContext.clients.filter(cc => cc.smst_abstract_uploaded === 'yes');
        }
        else if (fil === 'abstract-approved') {
            fClients = coreContext.clients.filter(cc => cc.smst_abstract_accepted === 'yes');
        }

        coreContext.setFilteredClients(fClients);

    }

    const changeFilterP = (fil) => {
        setFilterP(fil);

        let fClients = [];
        if (fil === '') fClients = coreContext.clients;
       
        else 
            fClients = coreContext.clients.filter(cc => cc.smst_presenting === fil);
        

        coreContext.setFilteredClients(fClients);

    }

    const changeSRole = (fil) => {
        setCategory(fil);

        let fClients = [];
        if (fil === '') fClients = coreContext.clients;

        else
        fClients = coreContext.clients.filter(cc => cc.smst_category === fil);


        coreContext.setFilteredClients(fClients);

    }

    const changeOrganizationFilter = (fil) => {
        setOrganization(fil);
        let fClients = [];
        if (fil === '') fClients = coreContext.clients;

        fClients = coreContext.clients.filter(cc => cc.smst_organization.toLowerCase().includes(fil.toLowerCase()) || cc.item_name.toLowerCase().includes(fil.toLowerCase()));

        coreContext.setFilteredClients(fClients);

    }

    const showDeleteWarning = (cl) => {
        // eslint-disable-next-line no-restricted-globals
        if(confirm('This applicant will be removed permanently, do you still want to delete ?')){
           coreContext.deleteClient(cl, authContext.ownerId);
        }
    }


    const renderClients = () => {
        if (coreContext.filteredClients) {
            return coreContext.filteredClients.map((client, index) => (
                <tr key={++index}>
                    <td>{index}</td>
                    <td>{client.item_name}</td>
                    <td>{client.item_email}</td>
                    <td>{client.smst_organization}</td>
                    <td>{client.smst_category}</td>
                    <td>{client.register_now_image_time ? new Date(client.register_now_image_time).toDateString(): ''}</td>
                    <td><Link className="btn btn-primary" to={`/applicant/${client.id}`} title="Manage Applicant"><Eye size="12" /></Link></td>
                    <td><button className="btn btn-danger" onClick={()=>showDeleteWarning(client.id)} href='#' title="Delete Applicant"><XSquare size="12" /></button></td>
                </tr>
            ));
        }
        else {
            return (<tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
            </tr>)
        }
    }

    return (

        <div className="card">
            {/* <div className="card-title mx-auto"> */}
            <div className="card-title">
                <div className='row'>
                    <div className="col-md-2"> <h3>
                        <Badge variant="success">Applicant's List</Badge>
                    </h3></div>
                    <div className="col-md-2">
                      
                    </div>
                    <div className="col-md-2">
                     
                    </div>
                    <div className="col-md-3">
                       
                    </div>
                    <div className="col-md-2">
                    <ReactHTMLTableToExcel
                    id="applicant-table-xls-button"
                    className="btn btn-primary"
                    table="applicantTable"
                    filename="tablexls"
                    sheet="tablexls"
                    buttonText="Download List as XLS"/>

                    </div>
                </div>
                <div className='row'>

                    <div className="col-md-2">
                        <Input label='Filter By' register={register} name='filter' elementType='select' options={filterOptions} onChange={(e) => changeFilter(e.target.value)} />
                    </div>
                    <div className="col-md-2">
                        <Input label='Filter By Presentation Mode' register={register} name='filterp' elementType='select' options={presentingOptions} onChange={(e) => changeFilterP(e.target.value)} />
                    </div>
                    <div className="col-md-2">
                        <Input label='Filter By Category' register={register} name='category' elementType='select' options={sRoleOptions} onChange={(e) => changeSRole(e.target.value)} />
                    </div>
                    <div className="col-md-3">
                        <Input label='Filter by' name='organization' minLength={2} maxLength={55} register={register} errors={errors} elementType='text' placeholder='Candidate / Organization Name' onChange={(e) => changeOrganizationFilter(e.target.value)} />
                    </div>
                  
                </div>
            </div>
            <div className="card-body table-responsive">
                <table className='table table-sm table-bordered' id='applicantTable'>
                    <thead>
                        <tr>
                            <th></th>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Institute</th>
                            <th>Category</th>
                            <th>Abstract Upload Time</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {renderClients()}
                    </tbody>
                </table>
            </div>
        </div>

    );

}


export { Clients };