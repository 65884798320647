/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState, useContext, useEffect } from 'react';
import ImageUploader from './common/UploadImage';
import Input from './common/Input';
import { Badge, Form } from 'react-bootstrap';
import { CoreContext } from '../context/core-context';
import { AuthContext } from '../context/auth-context';
import { useForm } from "react-hook-form";
import { useHistory } from 'react-router-dom';

const UploadReceipt = () => {

    const coreContext = useContext(CoreContext);
    const authContext = useContext(AuthContext);
    const [selectedImages, setSelectedImages] = useState([]);
    const [uploadButtonDisabled, setUploadButtonDisabled] = useState(true);

    const [image, setImage] = useState('');

    const history = useHistory();
    const fetchClient = () => {
        coreContext.fetchSelfEnrollToday(authContext.itemId);
    }

    const { register, handleSubmit, errors } = useForm({
        mode: 'onSubmit',
        reValidateMode: 'onBlur',
    });

    useEffect(fetchClient, []);

    const populateClient = () => {
      
        setImage(coreContext.client.enroll_today_image);
    }

    useEffect(populateClient, [coreContext.client]);

    const renderImages = () => {
        if (image){
            if (image.includes('.pdf'))
            return <div className="col-md-6"><a rel="noopener noreferrer" target="_blank" href={`http://${image}`}><img style={{ padding: 5 }} className="img-fluid" src={'https://node-websites.siddhantait.com/images/pdfimage.png'} alt='Receipt Copy' /></a></div>
            else
            return <div className="col-md-6"><a rel="noopener noreferrer" target="_blank" href={`http://${image}`}><img style={{ padding: 5 }} className="img-fluid" src={`http://${image}`} alt='Receipt Copy' /></a></div>
        }
    }

    const onImagesUploadHandler = async () => {
        const count = selectedImages.length;
        if (count > 1) { alert('Only one image should be uploaded for this section !'); return; }
        let counter = 0;

        for await (const image of selectedImages) {
            ++counter;
            const formData = new FormData();
            formData.append('itemId', coreContext.client.id);
            formData.append('image', image, coreContext.client.email);
            formData.append('userId', authContext.userId);
            coreContext.uploadEnrollTodayImage(formData, coreContext.client.id);

            if (counter === count) {
                setTimeout(() => {
                    setSelectedImages([]);
                    fetchClient();
                }, 2000);
                setTimeout(() => {
                    history.push('/');
                }, 5000);
            };
        }



    }

  

    const onImagesSelected = (pictures) => {
        setSelectedImages(pictures);
        //  console.log(pictures);
        if (pictures.length > 0) setUploadButtonDisabled(false); else setUploadButtonDisabled(true);
    }

    return (<div className='container-fluid'>

        <div className="card">
            <div className="card-title mx-auto"><h3><Badge variant="success">Upload Receipt</Badge></h3></div>
            <div className="card-title mx-auto">Best Image Size 1920*820</div>
            <div className="card-body">
                <ImageUploader onImagesSelected={onImagesSelected} maxWidthOrHeight='1920' pictures={selectedImages} />
                <Input value='Upload Receipt File' onClick={onImagesUploadHandler} disabled={uploadButtonDisabled} elementType='button' variant='primary' />&nbsp;&nbsp; {coreContext.renderLoader()}
                <Input variant='danger' label={coreContext.message} elementType='label' />
            </div>
            <div className="card-body">
                <div className="card-title mx-auto"><h3><Badge variant="success">Receipt Copy</Badge></h3></div>
                <div className="row">
                    {renderImages()}
                </div>
            </div>
        </div>
    </div>
    )
}

export { UploadReceipt }