import React, { useState, useEffect, useContext } from 'react';
import Axios from 'axios';
import loader from '../assets/images/835.gif';
import { AuthContext } from '../context/auth-context';
export const CoreContext = React.createContext({
    // categories: [],
    // roles: [],
    // features: [],
    // addCategory: () => { },
    // message: '',
    // showLoader: false
});


const CoreContextProvider = props => {

    const [categories, setCategories] = useState([]);
    const [roles, setRoles] = useState([]);
    const [roleOptions, setRoleOptions] = useState([]);
    const [categoryOptions, setCategoryOptions] = useState([]);
    const [features, setFeatures] = useState([]);
    const [clients, setClients] = useState([]);
    const [filteredClients, setFilteredClients] = useState([]);
    const [client, setClient] = useState({});
    const [users, setUsers] = useState([]);
    const [message, setMessage] = useState('');
    const [showLoader, setShowLoader] = useState(false);
    const [countries, setCountries] = useState([]);
    const [countryOptions, setCountryOptions] = useState([]);
    const [districts, setDistricts] = useState([]);
    const [districtOptions, setDistrictOptions] = useState([]);
    const [states, setStates] = useState([]);
    const [stateOptions, setStateOptions] = useState([]);
    const [localAreas, setLocalAreas] = useState([]);
    const [localAreaOptions, setLocalAreaOptions] = useState([]);
    const [homeSliderImages, setHomeSliderImages] = useState([]);
    const [educationalSolutionsImages, setEducationalSolutionsImages] = useState([]);
    const [teachersImages, setTeachersImages] = useState([]);
    const [testimonialImages, setTestimonialImages] = useState([]);
    const [events, setEvents] = useState([]);
    const [generals, setGenerals] = useState([]);
    const [pages, setPages] = useState([]);
    const [blogs, setBlogs] = useState([]);
    const [event, setEvent] = useState({});
    const [general, setGeneral] = useState({});
    const [page, setPage] = useState({});
    const [blog, setBlog] = useState({});
    const [menu, setMenu] = useState([]);
    const [isRequestComplete, setIsRequestComplete] = useState(false);
    const [pathName,setPathName] = useState('');

    const authContext = useContext(AuthContext);

    const addCategory = (name) => {
        setShowLoader(true);
        Axios.post('/addcategory', { name }).then(response => {
            setShowLoader(false);
            const value = response.data.message;
            setMessage(value);
            fetchCategories();
        }).catch(err => {
            setShowLoader(false);
            const value = err.response.data.data;
            setMessage(value[0].msg);
            setShowLoader(false);
        });
    }
    const editCategory = (name, id) => {
        setShowLoader(true);
        Axios.post('/editcategory', { name, id }).then(response => {
            setShowLoader(false);
            const value = response.data.message;
            setMessage(value);
            fetchCategories();
        }).catch(err => {
            setShowLoader(false);
            const value = err.response.data.data;
            setMessage(value[0].msg);
            setShowLoader(false);
        });
    }

    const deleteCategory = (id) => {
        setShowLoader(true);
        Axios.post('/deletecategory', { id }).then(response => {
            setShowLoader(false);
            const value = response.data.message;
            setMessage(value);
            fetchCategories();
        }).catch(err => {
            setShowLoader(false);
            const value = err.response.data.data;
            setMessage(value[0].msg);
            setShowLoader(false);
        });
    }
    const addLocalArea = (name, districtId, stateId, countryId, itemId) => {
        setShowLoader(true);
        Axios.post('/addlocalarea', { name, districtId, stateId, countryId, itemId }).then(response => {
            setShowLoader(false);
            const value = response.data.message;
            setMessage(value);
            fetchLocalAreas(itemId);
        }).catch(err => {
            setShowLoader(false);
            const value = err.response.data.data;
            setMessage(value[0].msg);
            setShowLoader(false);
        });
    }
    const editLocalArea = (name, districtId, stateId, countryId, itemId, id) => {
        setShowLoader(true);
        Axios.post('/editlocalarea', { name, districtId, stateId, countryId, itemId, id }).then(response => {
            setShowLoader(false);
            const value = response.data.message;
            setMessage(value);
            fetchLocalAreas(itemId);
        }).catch(err => {
            setShowLoader(false);
            const value = err.response.data.data;
            setMessage(value[0].msg);
            setShowLoader(false);
        });
    }

    const deleteLocalArea = (id, itemId) => {
        setShowLoader(true);
        Axios.post('/deletelocalarea', { id }).then(response => {
            setShowLoader(false);
            const value = response.data.message;
            setMessage(value);
            fetchLocalAreas(itemId);
        }).catch(err => {
            setShowLoader(false);
            const value = err.response.data.data;
            setMessage(value[0].msg);
            setShowLoader(false);
        });
    }

    const fetchCountries = () => {
        Axios.get('/countries').then(response => {
            setCountries(response.data.countries);
            const countryOptions = [{ value: '', name: 'Select' }];
            const countries = response.data.countries;
            countries.forEach(country => {
                countryOptions.push({ value: country.id, name: country.country_name })
            });

            setCountryOptions(countryOptions);


        }).catch(err => {
            checkLogOut(err);
        });
    }

    const fetchClient = (location, itemId) => {
        const url = location.pathname;
        Axios.get(url, { params: { itemId } }).then(response => {
            setFeatures(response.data.features);
            setClient(response.data.client);
            console.log(response.data.client);
        }).catch(err => {
            checkLogOut(err);
        });
    }

    const searchClient = (location) => {
        const url = location.pathname;
        Axios.get(url).then(response => {
            setFeatures(response.data.features);
            setClient(response.data.client);
            console.log(response.data.client);
        }).catch(err => { });
    }

    const fetchSelf = (itemId) => {
        setIsRequestComplete(false);
        Axios.get('/self', { params: { itemId } }).then(response => {
            setFeatures(response.data.features);
            setClient(response.data.client);
            fetchSliderImages(itemId);
            console.log(response.data.client);
        }).catch(err => { checkLogOut(err); });
    }

    const fetchOnlySelf = (itemId) => {
        Axios.get('/self', { params: { itemId } }).then(response => {
            setFeatures(response.data.features);
            setClient(response.data.client);
            //  console.log(response.data.client);
        }).catch(err => { checkLogOut(err); });
    }

    const fetchSelfImages = (itemId) => {
        setIsRequestComplete(false);
        Axios.get('/self', { params: { itemId } }).then(response => {
            setFeatures(response.data.features);
            setClient(response.data.client);
            fetchImages(itemId);
            //  console.log(response.data.client);
        }).catch(err => { checkLogOut(err); });
    }

    const fetchSelfEducationalSolutions = (itemId) => {
        setIsRequestComplete(false);
        Axios.get('/self', { params: { itemId } }).then(response => {
            setFeatures(response.data.features);
            setClient(response.data.client);
            fetchEducationSolutionsImages(itemId);
            //  console.log(response.data.client);
        }).catch(err => { checkLogOut(err); });

    }

    const fetchSelfTeachers = (itemId) => {
        setIsRequestComplete(false);
        Axios.get('/self', { params: { itemId } }).then(response => {
            setFeatures(response.data.features);
            setClient(response.data.client);
            fetchTeachersImages(itemId);
            //  console.log(response.data.client);
        }).catch(err => { checkLogOut(err); });
    }

    const fetchSelfTestimonial = (itemId) => {
        setIsRequestComplete(false);
        Axios.get('/self', { params: { itemId } }).then(response => {
            setFeatures(response.data.features);
            setClient(response.data.client);
            fetchTestimonialImages(itemId);
            //  console.log(response.data.client);
        }).catch(err => { checkLogOut(err); });
    }

    const fetchSelfEvents = (itemId) => {
        setIsRequestComplete(false);
        Axios.get('/self', { params: { itemId } }).then(response => {
            setFeatures(response.data.features);
            setClient(response.data.client);
            fetchEvents(itemId);
            //  console.log(response.data.client);
        }).catch(err => { checkLogOut(err); });
    }

    const fetchSelfGenerals = (itemId) => {
        setIsRequestComplete(false);
        Axios.get('/self', { params: { itemId } }).then(response => {
            setFeatures(response.data.features);
            setClient(response.data.client);
            fetchGenerals(itemId);
            //  console.log(response.data.client);
        }).catch(err => { checkLogOut(err); });
    }

    const fetchSelfBlogs = (itemId) => {
        setIsRequestComplete(false);
        Axios.get('/self', { params: { itemId } }).then(response => {
            setFeatures(response.data.features);
            setClient(response.data.client);
            fetchBlogs(itemId);
            //  console.log(response.data.client);
        }).catch(err => { checkLogOut(err); });
    }


    const fetchSelfPages = (itemId) => {
        setIsRequestComplete(false);
        Axios.get('/self', { params: { itemId } }).then(response => {
            setFeatures(response.data.features);
            setClient(response.data.client);
            fetchPages(itemId);
            //  console.log(response.data.client);
        }).catch(err => { checkLogOut(err); });

    }

    const fetchSelfRegisterNow = (itemId) => {
        setIsRequestComplete(false);
        Axios.get('/self', { params: { itemId } }).then(response => {
            setFeatures(response.data.features);
            setClient(response.data.client);
            //  console.log(response.data.client);
        }).catch(err => { checkLogOut(err); });
    }
    const fetchSelfEnrollToday = (itemId) => {
        Axios.get('/self', { params: { itemId } }).then(response => {
            setFeatures(response.data.features);
            setClient(response.data.client);
            //  console.log(response.data.client);
        }).catch(err => { checkLogOut(err); });
    }

    const deleteImage = (id) => {

        Axios.post('/delete-image', { id }).then((response) => {

        }).catch(err => { });
    }

    const deleteEvent = (id, itemId) => {

        Axios.post('/delete-event', { id, itemId }).then((response) => {
            // window.location.assign('/events');
            fetchEvents(itemId);

        }).catch(err => { });
    }
    const deleteGeneral = (id, itemId) => {

        Axios.post('/delete-general', { id, itemId }).then((response) => {
            // window.location.assign('/events');
            fetchGenerals(itemId);

        }).catch(err => { });
    }

    const fetchMenu = () => {
        Axios.get('/menus').then(response => {

            const menuOptions = [{ value: '', name: 'Select Menu' }];
            const menus = response.data.menus;
            menus.forEach(menu => {
                menuOptions.push({ value: menu.slug, name: menu.title })
            });


            setMenu(menuOptions);
        })
    }

    const deleteBlog = (id, itemId) => {

        Axios.post('/delete-blog', { id, itemId }).then((response) => {
            // window.location.assign('/events');
            fetchBlogs(itemId);

        }).catch(err => { });
    }

    const deletePage = (id, itemId) => {

        Axios.post('/delete-page', { id, itemId }).then((response) => {
            // window.location.assign('/events');
            fetchPages(itemId);

        }).catch(err => { });
    }

    const deleteClient = (id, itemId) => {

        Axios.post('/delete-client', { id, ownerId: itemId}).then((response) => {
            // window.location.assign('/events');
            fetchClients(itemId);

        }).catch(err => { });
    }

    const deleteTeachersImage = (id) => {

        Axios.post('/delete-teachers-image', { id }).then((response) => { }).catch(err => { });
    }

    const deleteTestimonialImage = (id) => {

        Axios.post('/delete-testimonial-image', { id }).then((response) => { }).catch(err => { });
    }

    const deleteVideo = (id, itemId) => {

        Axios.post('/delete-video', { id }).then((response) => {

        }).catch(err => { });
    }

    const showVideoOnFrontPage = (id, status, itemId) => {

        Axios.post('/show-video-infront', { id, status }).then((response) => {

        }).catch(err => { });
    }


    const fetchStates = (countryId) => {
        Axios.get('/states', { params: { countryId } }).then(response => {
            setStates(response.data.states);

            const stateOptions = [{ value: '', name: 'Select State' }];
            const states = response.data.states;
            states.forEach(state => {
                stateOptions.push({ value: state.id, name: state.state_name })
            });

            setStateOptions(stateOptions);
        }).catch(err => { });
    }

    const fetchDistricts = (countryId, stateId) => {
        Axios.get('/districts', { params: { countryId, stateId } }).then(response => {
            setDistricts(response.data.districts);

            const districtOptions = [{ value: '', name: 'Select District' }];
            const districts = response.data.districts;
            districts.forEach(district => {
                districtOptions.push({ value: district.id, name: district.district_name })
            });

            setDistrictOptions(districtOptions);
        }).catch(err => { });
    }

    const fetchAllLocalAreas = (countryId, stateId, districtId) => {
        Axios.get('/all-local-areas', { params: { countryId, stateId, districtId } }).then(response => {
            setLocalAreas(response.data.localAreas);

            const localAreaOptions = [{ value: '', name: 'Select Area' }];
            const localAreas = response.data.localAreas;
            localAreas.forEach(localArea => {
                localAreaOptions.push({ value: localArea.id, name: localArea.area_name })
            });

            setLocalAreaOptions(localAreaOptions);
        }).catch(err => { });
    }

    const fetchLocalAreas = (itemId) => {
        Axios.get('/local-areas', { params: { itemId } }).then(response => {
            setLocalAreas(response.data.localAreas);

            const localAreaOptions = [{ value: '', name: 'Select' }];
            const localAreas = response.data.localAreas;
            localAreas.forEach(localArea => {
                localAreaOptions.push({ value: localArea.id, name: localArea.area_name })
            });

            setLocalAreaOptions(localAreaOptions);
        }).catch(err => { });
    }

    const addCountry = (name) => {
        setShowLoader(true);
        Axios.post('/addcountry', { name }).then(response => {
            setShowLoader(false);
            const value = response.data.message;
            setMessage(value);
            fetchCountries();
        }).catch(err => {
            setShowLoader(false);
            const value = err.response.data.data;
            setMessage(value[0].msg);
            setShowLoader(false);
        });
    }
    const editCountry = (name, id) => {
        setShowLoader(true);
        Axios.post('/editcountry', { name, id }).then(response => {
            setShowLoader(false);
            const value = response.data.message;
            setMessage(value);
            fetchCountries();
        }).catch(err => {
            setShowLoader(false);
            const value = err.response.data.data;
            setMessage(value[0].msg);
            setShowLoader(false);
        });
    }

    const deleteCountry = (id) => {
        setShowLoader(true);
        Axios.post('/deletecountry', { id }).then(response => {
            setShowLoader(false);
            const value = response.data.message;
            setMessage(value);
            fetchCountries();
        }).catch(err => {
            setShowLoader(false);
            const value = err.response.data.data;
            setMessage(value[0].msg);
            setShowLoader(false);
        });
    }
    const addFeature = (name) => {
        setShowLoader(true);
        Axios.post('/addfeature', { name }).then(response => {
            setShowLoader(false);
            const value = response.data.message;
            setMessage(value);
            fetchFeatures();
        }).catch(err => {
            setShowLoader(false);
            const value = err.response.data.data;
            setMessage(value[0].msg);
            setShowLoader(false);
        });
    }
    const editFeature = (name, id) => {
        setShowLoader(true);
        Axios.post('/editfeature', { name, id }).then(response => {
            setShowLoader(false);
            const value = response.data.message;
            setMessage(value);
            fetchFeatures();
        }).catch(err => {
            setShowLoader(false);
            const value = err.response.data.data;
            setMessage(value[0].msg);
            setShowLoader(false);
        });
    }

    const deleteFeature = (id) => {
        setShowLoader(true);
        Axios.post('/deletefeature', { id }).then(response => {
            setShowLoader(false);
            const value = response.data.message;
            setMessage(value);
            fetchFeatures();
        }).catch(err => {
            setShowLoader(false);
            const value = err.response.data.data;
            setMessage(value[0].msg);
            setShowLoader(false);
        });
    }


    const addRole = (name) => {
        setShowLoader(true);
        Axios.post('/addrole', { name }).then(response => {
            setShowLoader(false);
            const value = response.data.message;
            setMessage(value);
            fetchRoles();
        }).catch(err => {
            setShowLoader(false);
            const value = err.response.data.data;
            setMessage(value[0].msg);
            setShowLoader(false);
        });
    }
    const editRole = (name, id) => {
        setShowLoader(true);
        Axios.post('/editrole', { name, id }).then(response => {
            setShowLoader(false);
            const value = response.data.message;
            setMessage(value);
            fetchRoles();
        }).catch(err => {
            setShowLoader(false);
            const value = err.response.data.data;
            setMessage(value[0].msg);
            setShowLoader(false);
        });
    }

    const deleteRole = (id) => {
        setShowLoader(true);
        Axios.post('/deleterole', { id }).then(response => {
            setShowLoader(false);
            const value = response.data.message;
            setMessage(value);
            fetchRoles();
        }).catch(err => {
            setShowLoader(false);
            const value = err.response.data.data;
            setMessage(value[0].msg);
            setShowLoader(false);
        });
    }

    const renderLoader = () => {
        if (showLoader) return (<span><img src={loader} alt="" /></span>);
        else return <span></span>
    }

    const fetchCategories = () => {
        Axios.get('/categories').then(response => {
            setCategories(response.data.categories);

            const categoryOptions = [{ value: '', name: 'Select' }];
            const categories = response.data.categories;
            categories.forEach(cat => {
                categoryOptions.push({ value: cat.id, name: cat.cat_name })
            });

            setCategoryOptions(categoryOptions);
        }).catch(err => { });
    }
    const fetchFeatures = () => {
        Axios.get('/features').then(response => {
            setFeatures(response.data.features);
        }).catch(err => { });
    }

    const fetchRoles = () => {
        Axios.get('/roles').then(response => {
            setRoles(response.data.roles);
        }).catch(err => { });
    }

    const fetchRoleOptions = () => {
        Axios.get('/roles').then(response => {
            setRoles(response.data.roles);
            const roleOptions = [{ value: '', name: 'Select' }];
            const roles = response.data.roles
            roles.forEach(role => {
                roleOptions.push({ value: role.id, name: role.role_name })
            });

            setRoleOptions(roleOptions);

            //   console.log(roleOptions);
        }).catch(err => { });
    }

    const fetchClients = (itemId) => {
        Axios.get('/clients', { params: { itemId } }).then(response => {
            //  console.log(response.data);
            setClients(response.data.clients);
            setFilteredClients(response.data.clients);
        }).catch(err => {
            checkLogOut(err);

        });
    }

    const searchClients = (stateId, districtId) => {
        setShowLoader(true);
        Axios.get('/search-clients', { params: { stateId, districtId } }).then(response => {
            //  console.log(response.data);
            setClients(response.data.clients);
            setFilteredClients(response.data.clients);
            setShowLoader(false);
        }).catch(err => {
            checkLogOut(err);
            setShowLoader(false);

        });
    }

    const checkLogOut = (err) => {
        console.log(err);
        if (err.response.status) {
            if (err.response.status === 401) {
                authContext.logOut();
                // window.location = '/';
            }
        }
    }

    const fetchUsers = (itemId) => {

        Axios.get('/users', { params: { itemId } }).then(response => {
            console.log(response.data);
            setUsers(response.data.users);
        }).catch(err => {
            checkLogOut(err);
        });
    }

    const updateHasImage = (ownerId, clientId, status) => {
        Axios.post('/update-has-image', { ownerId, clientId, status }).then(res => { }).catch(err => console.log(err));
    }

    const updateHasVideo = (ownerId, clientId, status) => {
        Axios.post('/update-has-video', { ownerId, clientId, status }).then(res => { }).catch(err => console.log(err));
    }

    const updateIsPageGallery = (clientId, pageId, status) => {
        Axios.post('/update-is-page-gallery', { clientId, pageId, status }).then(res => { }).catch(err => console.log(err));
    }

    const showInMenu = (clientId, pageId, status) => {
        Axios.post('/show-in-menu', { clientId, pageId, status }).then(res => { }).catch(err => console.log(err));
    }

    const updateEducationalSolutionsSection = (title, message, columns, itemId) => {
        Axios.post('/update-educational-solutions-sections', { title, message, columns, itemId }).then(response => {
            setShowLoader(false);
            const value = response.data.message;
            setMessage(value);
        }).catch(err => {
            setShowLoader(false);
            const value = err.response.data.data;
            setMessage(value[0].msg);
            setShowLoader(false);
        });
    }

    const check = () => {
        setIsRequestComplete(false);
        setIsRequestComplete(true);
    }

    const addEventContent = (title, message, content, edate, etime, itemId, userId) => {
        setIsRequestComplete(false);
        setShowLoader(true);
        Axios.post('/add-event-content', { title, message, content, edate, etime, itemId, userId }).then(response => {
            setShowLoader(false);
            const value = response.data.message;
            setMessage(value);
            setIsRequestComplete(true);
           // window.location.assign('/events');
        }).catch(err => {
            setShowLoader(false);
            const value = err.response.data.data;
            setMessage(value[0].msg);
            setShowLoader(false);

        });
    }

    const addGeneralContent = (title, message, content, edate, etime, itemId, userId) => {
        setIsRequestComplete(false);
        Axios.post('/add-general-content', { title, message, content, edate, etime, itemId, userId }).then(response => {
            setShowLoader(false);
            const value = response.data.message;
            setMessage(value);
            setIsRequestComplete(true);
        }).catch(err => {
            setShowLoader(false);
            const value = err.response.data.data;
            setMessage(value[0].msg);
            setShowLoader(false);

        });
    }

    const addBlogContent = (title, message, content, edate, cat, showInPopUP, itemId, userId) => {
        setIsRequestComplete(false);
        Axios.post('/add-blog-content', { title, message, content, edate, cat, showInPopUP, itemId, userId }).then(response => {
            setShowLoader(false);
            const value = response.data.message;
            setMessage(value);
            setIsRequestComplete(true);
        }).catch(err => {
            setShowLoader(false);
            const value = err.response.data.data;
            setMessage(value[0].msg);
            setShowLoader(false);

        });
    }

    const addPageContent = (title, message, content, slug, menu, itemId, userId) => {

        Axios.post('/add-page-content', { title, message, content, slug, menu, itemId, userId }).then(response => {
            setShowLoader(false);
            const value = response.data.message;
            setMessage(value);
           // window.location.assign('/pages');
        }).catch(err => {
            setShowLoader(false);
            const value = err.response.data.data;
            setMessage(value[0].msg);
            setShowLoader(false);

        });
    }

    const updateEventContent = (title, message, content, edate, etime, itemId, eventId) => {
        Axios.post('/update-event-content', { title, message, content, edate, etime, itemId, eventId }).then(response => {
            setShowLoader(false);
            const value = response.data.message;
            setMessage(value);
        }).catch(err => {
            setShowLoader(false);
            const value = err.response.data.data;
            setMessage(value[0].msg);
            setShowLoader(false);
        });
    }

    const updateGeneralContent = (title, message, content, edate, etime, itemId, generalId) => {
        Axios.post('/update-general-content', { title, message, content, edate, etime, itemId, generalId }).then(response => {
            setShowLoader(false);
            const value = response.data.message;
            setMessage(value);
        }).catch(err => {
            setShowLoader(false);
            const value = err.response.data.data;
            setMessage(value[0].msg);
            setShowLoader(false);
        });
    }

    const updateBlogContent = (title, message, content, edate, cat, showInPopUP, status, itemId, blogId) => {
        Axios.post('/update-blog-content', { title, message, content, edate, cat, showInPopUP, status, itemId, blogId }).then(response => {
            setShowLoader(false);
            const value = response.data.message;
            setMessage(value);
        }).catch(err => {
            setShowLoader(false);
            const value = err.response.data.data;
            setMessage(value[0].msg);
            setShowLoader(false);
        });
    }

    const updatePageContent = (title, message, content, slug, itemId, pageId) => {
        Axios.post('/update-page-content', { title, message, content, slug, itemId, pageId }).then(response => {
            setShowLoader(false);
            const value = response.data.message;
            setMessage(value);
        }).catch(err => {
            setShowLoader(false);
            const value = err.response.data.data;
            setMessage(value[0].msg);
            setShowLoader(false);
        });
    }

    const updateEventSection = (title, message, itemId) => {
        Axios.post('/update-event-sections', { title, message, itemId }).then(response => {
            setShowLoader(false);
            const value = response.data.message;
            setMessage(value);
        }).catch(err => {
            setShowLoader(false);
            const value = err.response.data.data;
            setMessage(value[0].msg);
            setShowLoader(false);
        });
    }

    const updateGeneralSection = (title, message, itemId) => {
        Axios.post('/update-general-sections', { title, message, itemId }).then(response => {
            setShowLoader(false);
            const value = response.data.message;
            setMessage(value);
        }).catch(err => {
            setShowLoader(false);
            const value = err.response.data.data;
            setMessage(value[0].msg);
            setShowLoader(false);
        });
    }

    const updateBlogSection = (title, message, itemId) => {
        Axios.post('/update-blog-sections', { title, message, itemId }).then(response => {
            setShowLoader(false);
            const value = response.data.message;
            setMessage(value);
        }).catch(err => {
            setShowLoader(false);
            const value = err.response.data.data;
            setMessage(value[0].msg);
            setShowLoader(false);
        });
    }

    const updateTeachersSection = (title, message, itemId) => {
        Axios.post('/update-teachers-sections', { title, message, itemId }).then(response => {
            setShowLoader(false);
            const value = response.data.message;
            setMessage(value);
        }).catch(err => {
            setShowLoader(false);
            const value = err.response.data.data;
            setMessage(value[0].msg);
            setShowLoader(false);
        });
    }

    const updateEnrollTodaySection = (title, message, firstNumber, secondNumber, thirdNumber, firstLabel, secondLabel, thirdLabel, itemId) => {
        Axios.post('/update-enroll-today-sections', { title, message, firstNumber, secondNumber, thirdNumber, firstLabel, secondLabel, thirdLabel, itemId }).then(response => {
            setShowLoader(false);
            const value = response.data.message;
            setMessage(value);
        }).catch(err => {
            setShowLoader(false);
            const value = err.response.data.data;
            setMessage(value[0].msg);
            setShowLoader(false);
        });
    }

    const updateFFSection = (firstNumber, secondNumber, thirdNumber, fourthNumber, firstLabel, secondLabel, thirdLabel, fourthLabel, firstIcon, secondIcon, thirdIcon, fourthIcon, itemId) => {
        Axios.post('/update-ff-sections', { firstNumber, secondNumber, thirdNumber, fourthNumber, firstLabel, secondLabel, thirdLabel, fourthLabel, firstIcon, secondIcon, thirdIcon, fourthIcon, itemId }).then(response => {
            setShowLoader(false);
            const value = response.data.message;
            setMessage(value);
        }).catch(err => {
            setShowLoader(false);
            const value = err.response.data.data;
            setMessage(value[0].msg);
            setShowLoader(false);
        });
    }
    const updateRegisterNowSection = (title, message, itemId) => {
        Axios.post('/update-register-now-sections', { title, message, itemId }).then(response => {
            setShowLoader(false);
            const value = response.data.message;
            setMessage(value);
        }).catch(err => {
            setShowLoader(false);
            const value = err.response.data.data;
            setMessage(value[0].msg);
            setShowLoader(false);
        });
    }

    const fetchImages = (itemId) => {
        console.log('itemId', itemId);
        Axios.get('/images', { params: { itemId } }).then(response => {
            const cl = { ...client, images: response.data.images };
            setClient(cl);
            console.log(response.data.images);
        }).catch(err => { });
    }

    const updateEducationalSolutionsImage = (imageTitle, imageMessage, button1Title, button1Link, itemId, imageId) => {

        Axios.post('update-educational-soutions-image', { imageTitle, imageMessage, button1Title, button1Link, itemId, imageId }).then(response => {
            setShowLoader(false);
            const value = response.data.message;
            alert(value);
        }).catch(err => {
            setShowLoader(false);
            const value = err.response.data.data;
            setMessage(value[0].msg);
            setShowLoader(false);
        });
    }

    const updateGeneralImage = (imageTitle, imageMessage, imageOrder, itemId, imageId) => {

        Axios.post('update-general-image', { imageTitle, imageMessage, imageOrder, itemId, imageId }).then(response => {
            setShowLoader(false);
            const value = response.data.message;
            alert(value);
        }).catch(err => {
            setShowLoader(false);
            const value = err.response.data.data;
            setMessage(value[0].msg);
            setShowLoader(false);
        });
    }

    const updatePageImage = (imageRemarks, imageOrder, itemId, imageId) => {

        Axios.post('update-page-image', { imageRemarks, imageOrder, itemId, imageId }).then(response => {
            setShowLoader(false);
            const value = response.data.message;
            alert(value);
        }).catch(err => {
            setShowLoader(false);
            const value = err.response.data.data;
            setMessage(value[0].msg);
            setShowLoader(false);
        });
    }

    const updateTeachersImage = (imageTitle, imageMessage, imageDesignation, link1, link2, link3, link4, itemId, imageId) => {

        Axios.post('update-teachers-image', { imageTitle, imageMessage, imageDesignation, link1, link2, link3, link4, itemId, imageId }).then(response => {
            setShowLoader(false);
            const value = response.data.message;
            alert(value);
        }).catch(err => {
            setShowLoader(false);
            const value = err.response.data.data;
            setMessage(value[0].msg);
            setShowLoader(false);
        });
    }

    const updateTestimonialImage = (imageTitle, imageMessage, itemId, imageId) => {

        Axios.post('update-testimonial-image', { imageTitle, imageMessage, itemId, imageId }).then(response => {
            setShowLoader(false);
            const value = response.data.message;
            alert(value);
        }).catch(err => {
            setShowLoader(false);
            const value = err.response.data.data;
            setMessage(value[0].msg);
            setShowLoader(false);
        });
    }
    const updateSliderImage = (imageTitle, imageMessage, button1Title, button2Title, button1Link, button2Link, itemId, imageId) => {

        Axios.post('update-slider', { imageTitle, imageMessage, button1Title, button2Title, button1Link, button2Link, itemId, imageId }).then(response => {
            setShowLoader(false);
            const value = response.data.message;
            alert(value);
        }).catch(err => {
            setShowLoader(false);
            const value = err.response.data.data;
            setMessage(value[0].msg);
            setShowLoader(false);
        });
    }


    const fetchSliderImages = (itemId) => {
        //  console.log('itemId', itemId);
        Axios.get('/slider-images', { params: { itemId } }).then(response => {
            setHomeSliderImages(response.data.images);

        }).catch(err => { });
    }

    const fetchEducationSolutionsImages = (itemId) => {
        //  console.log('itemId', itemId);
        Axios.get('/educational-solutions-images', { params: { itemId } }).then(response => {
            setEducationalSolutionsImages(response.data.images);

        }).catch(err => { });
    }


    const fetchEventImages = (itemId, eventId) => {
        console.log('itemId', itemId);
        Axios.get('/event-images', { params: { itemId, eventId } }).then(response => {
            const cl = { ...event, event_images: response.data.images };
            setEvent(cl);
            // console.log(response.data.images);
        }).catch(err => { });
    }

    const fetchGeneralImages = (itemId, generalId) => {
        // console.log('itemId', itemId);
        Axios.get('/general-images', { params: { itemId, generalId } }).then(response => {
            const cl = { ...general, general_images: response.data.images };
            setGeneral(cl);
            // console.log(response.data.images);
        }).catch(err => { });
    }

    const fetchBlogImages = (itemId, blogId) => {
        //  console.log('itemId', itemId);
        Axios.get('/blog-images', { params: { itemId, blogId } }).then(response => {
            const cl = { ...blog, blog_images: response.data.images };
            setBlog(cl);
            // console.log(response.data.images);
        }).catch(err => { });
    }

    const fetchPageImages = (itemId, pageId) => {
        //   console.log('itemId', itemId);
        Axios.get('/page-images', { params: { itemId, pageId } }).then(response => {
            const cl = { ...page, page_images: response.data.images };
            setPage(cl);
            // console.log(response.data.images);
        }).catch(err => { });
    }

    const fetchTeachersImages = (itemId) => {
        //  console.log('itemId', itemId);
        Axios.get('/teachers-images', { params: { itemId } }).then(response => {
            setTeachersImages(response.data.images);

        }).catch(err => { });
    }

    const fetchTestimonialImages = (itemId) => {
        //  console.log('itemId', itemId);
        Axios.get('/testimonial-images', { params: { itemId } }).then(response => {
            setTestimonialImages(response.data.images);

        }).catch(err => { });
    }

    const fetchEvents = (itemId) => {

        Axios.get('/events', { params: { itemId } }).then(response => {
            setEvents(response.data.events);

        }).catch(err => { });
    }
    const fetchGenerals = (itemId) => {

        Axios.get('/generals', { params: { itemId } }).then(response => {
            setGenerals(response.data.generals);

        }).catch(err => { });
    }

    const fetchBlogs = (itemId) => {

        Axios.get('/blogs', { params: { itemId } }).then(response => {
            setBlogs(response.data.blogs);

        }).catch(err => { });
    }

    const comparePages = (a, b) => {
        if (a.menu < b.menu) {
            return -1;
        }
        if (a.menu > b.menu) {
            return 1;
        }
        return 0;
    }


    const fetchPages = (itemId) => {

        Axios.get('/pages', { params: { itemId } }).then(response => {
            const pages = response.data.pages.sort(comparePages);
            pages.forEach((page, index) => {
                if (page.display_order === 0) page.display_order = index + 1;
            });
            setPages(pages);

            // console.log(pages);
        }).catch(err => { });
    }

    const updatePageDisplayOrder = (pages) => {
        Axios.post('update-page-display-order', { pages }).then(res => {

        })
    }

    const fetchEvent = (location, itemId) => {
        const url = location.pathname;
        Axios.get(url, { params: { itemId } }).then(response => {
            setEvent(response.data.event);
            //console.log(response.data.event);
        }).catch(err => {
            checkLogOut(err);
        });
    }

    const fetchGeneral = (location, itemId) => {
        const url = location.pathname;
        Axios.get(url, { params: { itemId } }).then(response => {
            setGeneral(response.data.general);
            //console.log(response.data.event);
        }).catch(err => {
            checkLogOut(err);
        });
    }

    const fetchBlog = (location, itemId) => {
        const url = location.pathname;
        Axios.get(url, { params: { itemId } }).then(response => {
            setBlog(response.data.blog);
            //console.log(response.data.event);
        }).catch(err => {
            checkLogOut(err);
        });
    }

    const fetchPage = (location, itemId) => {
        const url = location.pathname;
        Axios.get(url, { params: { itemId } }).then(response => {
            setPage(response.data.page);
            //   console.log(response.data.event);
        }).catch(err => {

            checkLogOut(err);
        });
    }

    const uploadImage = (formData, itemId) => {
        setShowLoader(true);
        Axios.post('/upload-image', formData).then(response => {
            setShowLoader(false);
            const value = response.data.message;
            setMessage(value);
        }).catch(err => {
            setShowLoader(false);
            const value = err.response.data.data;
            setMessage(value[0].msg);
            setShowLoader(false);
        });
    }

    const uploadSliderImage = (formData, itemId) => {
        setShowLoader(true);
        Axios.post('/upload-slider-image', formData).then(response => {
            setShowLoader(false);
            const value = response.data.message;
            setMessage(value);
        }).catch(err => {
            setShowLoader(false);
            const value = err.response.data.data;
            setMessage(value[0].msg);
            setShowLoader(false);
        });
    }

    const uploadEducationalSolutionsImage = (formData, itemId) => {

        setShowLoader(true);
        Axios.post('/upload-educational-solutions-image', formData).then(response => {
            setShowLoader(false);
            const value = response.data.message;
            setMessage(value);
        }).catch(err => {
            setShowLoader(false);
            const value = err.response.data.data;
            setMessage(value[0].msg);
            setShowLoader(false);
        });
    }

    const uploadEventImage = (formData, itemId, eventId) => {

        setShowLoader(true);
        Axios.post('/upload-event-image', formData).then(response => {
            setShowLoader(false);
            const value = response.data.message;
            setMessage(value);
        }).catch(err => {
            setShowLoader(false);
            const value = err.response.data.data;
            setMessage(value[0].msg);
            setShowLoader(false);
        });
    }

    const uploadGeneralImage = (formData, itemId, generalId) => {

        setShowLoader(true);
        Axios.post('/upload-general-image', formData).then(response => {
            setShowLoader(false);
            const value = response.data.message;
            setMessage(value);
        }).catch(err => {
            setShowLoader(false);
            const value = err.response.data.data;
            setMessage(value[0].msg);
            setShowLoader(false);
        });
    }

    const uploadBlogImage = (formData, itemId, blogId) => {

        setShowLoader(true);
        Axios.post('/upload-blog-image', formData).then(response => {
            setShowLoader(false);
            const value = response.data.message;
            setMessage(value);
        }).catch(err => {
            setShowLoader(false);
            const value = err.response.data.data;
            setMessage(value[0].msg);
            setShowLoader(false);
        });
    }

    const uploadPageImage = (formData, itemId, pageId) => {

        setShowLoader(true);
        Axios.post('/upload-page-image', formData).then(response => {
            setShowLoader(false);
            const value = response.data.message;
            setMessage(value);
        }).catch(err => {
            setShowLoader(false);
            const value = err.response.data.data;
            setMessage(value[0].msg);
            setShowLoader(false);
        });
    }

    const uploadTeachersImage = (formData, itemId) => {

        setShowLoader(true);
        Axios.post('/upload-teachers-image', formData).then(response => {
            setShowLoader(false);
            const value = response.data.message;
            setMessage(value);
        }).catch(err => {
            setShowLoader(false);
            const value = err.response.data.data;
            setMessage(value[0].msg);
            setShowLoader(false);
        });
    }

    const uploadTestimonialImage = (formData, itemId) => {

        setShowLoader(true);
        Axios.post('/upload-testimonial-image', formData).then(response => {
            setShowLoader(false);
            const value = response.data.message;
            setMessage(value);
        }).catch(err => {
            setShowLoader(false);
            const value = err.response.data.data;
            setMessage(value[0].msg);
            setShowLoader(false);
        });
    }


    const uploadEnrollTodayImage = (formData, itemId) => {

        setShowLoader(true);
        Axios.post('/upload-payment-receipt', formData).then(response => {
            setShowLoader(false);
            const value = response.data.message;
            setMessage(value);
        }).catch(err => {
            setShowLoader(false);
            const value = err.response.data.data;
            setMessage(value[0].msg);
            setShowLoader(false);
        });
    }

    const uploadRegisterNowImage = (formData, itemId) => {

        setShowLoader(true);
        Axios.post('/upload-abstract', formData).then(response => {
            setShowLoader(false);
            const value = response.data.message;
            setMessage(value);
        }).catch(err => {
            setShowLoader(false);
            const value = err.response.data.data;
            setMessage(value[0].msg);
            setShowLoader(false);
        });
    }

    const uploadProfileImage = (formData, itemId) => {

        setShowLoader(true);
        Axios.post('/upload-profile', formData).then(response => {
            setShowLoader(false);
            const value = response.data.message;
            setMessage(value);
        }).catch(err => {
            setShowLoader(false);
            const value = err.response.data.data;
            setMessage(value[0].msg);
            setShowLoader(false);
        });
    }



    const uploadVideoLink = (url, message = '', itemId, userId) => {
        setShowLoader(true);
        Axios.post('/upload-video', { url, message, itemId, userId }).then(response => {
            setShowLoader(false);
            const value = response.data.message;
            setMessage(value);
        }).catch(err => {
            setShowLoader(false);
            const value = err.response.data.data;
            setMessage(value[0].msg);
            setShowLoader(false);
        });
    }

    const fetchVideos = (itemId) => {
        Axios.get('/videos', { params: { itemId } }).then(response => {
            const cl = { ...client, video_links: response.data.videos };
            setClient(cl);
            console.log('client Videos', cl);
        }).catch(err => {
            checkLogOut(err);
        });
    }

    const editClient = (id, ownerId, name, email, address, contact1, contact2, contactName, tag, loc, contact, description, pincode, localArea, stateId, districtId, category, countryId, status, siteUrl, hideMenu, hideFooter, erpBranchid, isCollege, nationality, sCategory, organization, designation, accomodation, presenting, accompanying, paymentConfirmed, abstractApproved) => {
        setShowLoader(true);
        Axios.post('/edit-client', { id, ownerId, name, email, address, contact1, contact2, contactName, tag, loc, contact, description, pincode, localArea, stateId, districtId, category, countryId, status, siteUrl, hideMenu, hideFooter, erpBranchid, isCollege, nationality, sCategory, organization, designation, accomodation, presenting, accompanying, paymentConfirmed, abstractApproved }).then(response => {
            setShowLoader(false);
            const value = response.data.message;
            setMessage(value);
        }).catch(err => {
            setShowLoader(false);
            const value = err.response.data.data;
            setMessage(value[0].msg);
            setShowLoader(false);
        });
    }

    const applyThemeColor = (webColor, itemId) => {

        Axios.post('/change-theme-color', { webColor, itemId }).then(response => {
            setShowLoader(false);
            const value = response.data.message;
            setMessage(value);
        }).catch(err => {
            setShowLoader(false);
            const value = err.response.data.data;
            setMessage(value[0].msg);
            setShowLoader(false);
        });

    }

    const editSelf = (ownerId, name, email, address, contact1, contact2, contactName, tag, loc, contact, description, pincode, localArea, stateId, districtId, category, countryId, status, otime, twitter, facebook, google, youtube, metaKeyWord, applyURL, webColor, bigHeader, sliderHeight, menuFontSize, leftHeaderSlug, rightHeaderSlug, googleAnalytics, sliderWidth, sliderSidePadding, showTopBar, showBelowBar, leftMenuSlug, leftMenuSpace) => {
        setShowLoader(true);
        Axios.post('/edit-self', { ownerId, name, email, address, contact1, contact2, contactName, tag, loc, contact, description, pincode, localArea, stateId, districtId, category, countryId, status, otime, twitter, facebook, google, youtube, metaKeyWord, applyURL, webColor, bigHeader, sliderHeight, menuFontSize, leftHeaderSlug, rightHeaderSlug, googleAnalytics, sliderWidth, sliderSidePadding, showTopBar, showBelowBar, leftMenuSlug, leftMenuSpace }).then(response => {
            setShowLoader(false);
            const value = response.data.message;
            setMessage(value);
        }).catch(err => {
            setShowLoader(false);
            const value = err.response.data.data;
            setMessage(value[0].msg);
            setShowLoader(false);
        });
    }


    useEffect(fetchCategories, []);
    useEffect(fetchFeatures, []);
    useEffect(fetchRoles, []);

    return (
        <CoreContext.Provider value={{
            isRequestComplete,
            pathName,
            setPathName,
            categories,
            homeSliderImages,
            educationalSolutionsImages,
            roles,
            features,
            clients,
            filteredClients,
            setFilteredClients,
            users,
            addCategory,
            setIsRequestComplete,
            editCategory,
            deleteCategory,
            message,
            showLoader,
            renderLoader,
            setMessage,
            setGeneral,
            addFeature,
            check,
            editFeature,
            deleteFeature,
            addRole,
            editRole,
            deleteRole,
            fetchClients,
            searchClients,
            fetchSelf,
            fetchSelfEducationalSolutions,
            fetchSelfPages,
            fetchSelfEvents,
            fetchSelfGenerals,
            fetchSelfBlogs,
            fetchSelfTeachers,
            fetchSelfTestimonial,
            fetchSelfEnrollToday,
            fetchSelfRegisterNow,
            fetchUsers,
            roleOptions,
            fetchRoleOptions,
            countries,
            states,
            events,
            generals,
            blogs,
            pages,
            districts,
            localAreas,
            fetchCountries,
            fetchStates,
            fetchDistricts,
            fetchLocalAreas,
            addCountry,
            editCountry,
            deleteCountry,
            countryOptions,
            categoryOptions,
            stateOptions,
            districtOptions,
            localAreaOptions,
            addLocalArea,
            editLocalArea,
            deleteLocalArea,
            fetchClient,
            searchClient,
            client,
            editClient,
            updateHasImage,
            updateHasVideo,
            updateIsPageGallery,
            showInMenu,
            uploadImage,
            uploadVideoLink,
            deleteImage,
            deleteEvent,
            deleteGeneral,
            deleteBlog,
            deletePage,
            deleteTeachersImage,
            deleteTestimonialImage,
            deleteVideo,
            showVideoOnFrontPage,
            fetchImages,
            fetchVideos,
            editSelf,
            applyThemeColor,
            fetchAllLocalAreas,
            uploadSliderImage,
            fetchSliderImages,
            updateSliderImage,
            updateEducationalSolutionsImage,
            updateGeneralImage,
            updatePageImage,
            updateTeachersImage,
            updateTestimonialImage,
            setHomeSliderImages,
            setEducationalSolutionsImages,
            teachersImages,
            setTeachersImages,
            setTestimonialImages,
            testimonialImages,
            uploadEducationalSolutionsImage,
            uploadEventImage,
            uploadGeneralImage,
            uploadBlogImage,
            uploadPageImage,
            uploadTeachersImage,
            uploadTestimonialImage,
            uploadEnrollTodayImage,
            uploadRegisterNowImage,
            uploadProfileImage,
            fetchEducationSolutionsImages,
            fetchEventImages,
            fetchGeneralImages,
            deleteClient,
            fetchBlogImages,
            fetchPageImages,
            fetchTeachersImages,
            fetchTestimonialImages,
            fetchEvents,
            fetchBlogs,
            fetchPages,
            fetchPage,
            fetchEvent,
            fetchGeneral,
            fetchBlog,
            fetchOnlySelf,
            event,
            general,
            blog,
            page,
            updateEducationalSolutionsSection,
            updateEventContent,
            updateGeneralContent,
            updateBlogContent,
            updatePageContent,
            addEventContent,
            addGeneralContent,
            addBlogContent,
            addPageContent,
            updateEventSection,
            updateGeneralSection,
            updateBlogSection,
            updateTeachersSection,
            updateEnrollTodaySection,
            updateFFSection,
            updateRegisterNowSection,
            fetchMenu,
            menu,
            setPages,
            updatePageDisplayOrder,
            fetchSelfImages

        }}>
            {props.children}
        </CoreContext.Provider >
    );
}

export default CoreContextProvider;