/* eslint-disable react/no-danger-with-children */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState, useContext, useEffect } from 'react';
import { CirclePicker } from 'react-color';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { CoreContext } from '../context/core-context';
import { AuthContext } from '../context/auth-context';
import { XSquare } from 'react-bootstrap-icons';
import { Badge, Form } from 'react-bootstrap';
import Input from './common/Input';
import ImageUploader from './common/UploadImage';


const Self = (props) => {
    const coreContext = useContext(CoreContext);
    const authContext = useContext(AuthContext);
    const history = useHistory();
    const [name, setName] = useState('');
    const [contactName, setContactName] = useState('');
    const [tag, setTag] = useState('');
    const [loc, setLOC] = useState('');
    const [email, setEmail] = useState('');
    const [contact1, setContact1] = useState('');
    const [contact2, setContact2] = useState();
    const [contact, setContact] = useState();
    const [address, setAddress] = useState();
    const [description, setDescription] = useState();
    const [pincode, setPincode] = useState();
    const [password, setPassword] = useState();
    const [localArea, setLocalArea] = useState('');
    const [bigHeader, setBigHeader] = useState('no');
    const [maxImagesAllowed, setMaxImagesAllowed] = useState(2);
    const [stateId, setStateId] = useState('');
    const [districtId, setDistrictId] = useState('');
    const [category, setCategory] = useState(3);
    const [countryId, setCountryId] = useState('');
    const [hasImages, setHasImages] = useState(true);
    const [images, setImages] = useState([]);
    const [icons, setIcons] = useState([]);
    const [videos, setVideos] = useState([]);

    const [otime, setOtime] = useState('');
    const [twitter, setTwitter] = useState('');
    const [facebook, setFacebook] = useState('');
    const [google, setGoogle] = useState('');
    const [youtube, setYoutube] = useState('');
    const [metaKeyWord, setMetaKeyWord] = useState('');
    const [applyURL, setApplyURL] = useState('');
    const [sliderHeight, setSliderHeight] = useState('');
    const [sliderWidth, setSliderWidth] = useState('normal');
    const [sliderSidePadding, setSliderSidePadding] = useState('');
    const [showTopBar, setShowTopBar] = useState('yes');
    const [showBelowBar, setShowBelowBar] = useState('yes');
    const [menuFontSize, setMenuFontSize] = useState('');
    const [leftHeaderSlug, setLeftHeaderSlug] = useState('');
    const [leftMenuSlug, setLeftMenuSlug] = useState('');
    const [leftMenuSpace, setLeftMenuSpace] = useState('');
    const [rightHeaderSlug, setRightHeaderSlug] = useState('');
    const [googleAnalytics, setGoogleAnalytics] = useState('');
    const [webColor, setWebColor] = useState('');
    const [bigHeaderOptions] = useState([{ name: 'Yes', value: 'yes' }, { name: 'No', value: 'no' }]);
    const [topBarOptions] = useState([{ name: 'Yes', value: 'yes' }, { name: 'No', value: 'no' }]);

    const [belowBarOptions] = useState([{ name: 'Yes', value: 'yes' }, { name: 'No', value: 'no' }]);

    const [menuLeftSpaceOptions] = useState([{ name: 'None', value: '' }, { name: '1', value: '1' }, { name: '2', value: '2' }, { name: '3', value: '3' }, { name: '4', value: '4' }, { name: '5', value: '5' }, { name: '6', value: '6' }]);

    const [sliderWidthOptions] = useState([{ name: 'Normal', value: 'normal' }, { name: 'Small', value: 'small' }, { name: 'X-Small', value: 'x-small' }]);


    const [uploadButtonDisabled, setUploadButtonDisabled] = useState(true);
    const [iconUploadButtonDisabled, setIconUploadButtonDisabled] = useState(true);
    const [selectedImages, setSelectedImages] = useState([]);

    const [status, setStatus] = useState('');

    //useEffect(setClient(coreContext.client), [client]);

    const fetchClient = () => {

        //this.props.match.params.id //accessing param
        console.log('params', props.match);
        coreContext.fetchSelf(authContext.itemId);
    }

    useEffect(fetchClient, []);


    const loadLoactionOptions = () => {
        coreContext.fetchCountries();
        if (coreContext.client.countryId)
            coreContext.fetchStates(coreContext.client.countryId);
        if (coreContext.client.stateId)
            coreContext.fetchDistricts(coreContext.client.countryId, coreContext.client.stateId);
        if (coreContext.client.id)
            coreContext.fetchLocalAreas(coreContext.client.id);
    }

    const deleteImage = (id) => {
        const newImages = images.filter(image => image.id !== id);
        setImages(newImages);
        coreContext.deleteImage(id);
    }

    const populateClient = () => {
        //   console.log(coreContext.client);
        setName(coreContext.client.item_name);
        setContactName(coreContext.client.item_contact_person);
        setTag(coreContext.client.item_tag);
        setLOC(coreContext.client.item_loc);
        setEmail(coreContext.client.item_email);
        setContact1(coreContext.client.item_mobile_1);
        setContact2(coreContext.client.item_mobile_2);
        setContact(coreContext.client.item_contact);
        setAddress(coreContext.client.item_address);
        setDescription(coreContext.client.item_description);
        setPincode(coreContext.client.item_pincode);
        setLocalArea(coreContext.client.areaId);
        setStateId(coreContext.client.stateId);
        setDistrictId(coreContext.client.districtId);
        setCountryId(coreContext.client.countryId);
        setImages(coreContext.client.images);
        setVideos(coreContext.client.video_links);
        setStatus(coreContext.client.item_status);
        setBigHeader(coreContext.client.show_big_header);
        setLeftMenuSlug(coreContext.client.menu_left_slug);
        setLeftMenuSpace(coreContext.client.menu_side_space_left);

        setOtime(coreContext.client.otime);
        setTwitter(coreContext.client.twitter);
        setFacebook(coreContext.client.facebook);
        setGoogle(coreContext.client.google);
        setYoutube(coreContext.client.youtube);
        setMetaKeyWord(coreContext.client.site_meta_keyword);
        setApplyURL(coreContext.client.apply_url);
        setWebColor(coreContext.client.theme_color);
        setSliderHeight(coreContext.client.slider_height);
        setSliderWidth(coreContext.client.slider_width);
        setSliderSidePadding(coreContext.client.slider_side_padding);
        setShowTopBar(coreContext.client.show_top_bar);
        setShowBelowBar(coreContext.client.show_below_bar);
        setMenuFontSize(coreContext.client.menu_font_size);
        setLeftHeaderSlug(coreContext.client.logo_side_space_left);
        setRightHeaderSlug(coreContext.client.logo_side_space_right);
        setGoogleAnalytics(coreContext.client.google_analytics);
    }

    useEffect(loadLoactionOptions, [coreContext.client]);
    useEffect(populateClient, [coreContext.client]);

    const renderImages = () => {
        if (images) {
            const logos = images.filter(image => image.itype === 'logo');

            if (logos)
                return logos.map(image => <div className="col-md-6"><img style={{ padding: 5 }} className="img-fluid" src={`http://${image.url}`} alt='gallery image' /> <button onClick={() => deleteImage(image.id)} style={{ position: 'absolute', top: 0, right: 15 }} type="button" className="btn btn-danger btn-sm" title="Delete Image"> <XSquare size="12" /> </button>

                </div>)
        }
    }

    const renderIcons = () => {
        if (images) {
            const icons = images.filter(image => image.itype === 'icon');
            if (icons)
                return icons.map(image => <div className="col-md-6"><img style={{ padding: 5 }} className="img-fluid" src={`http://${image.url}`} alt='gallery image' /> <button onClick={() => deleteImage(image.id)} style={{ position: 'absolute', top: 0, right: 15 }} type="button" className="btn btn-danger btn-sm" title="Delete Image"> <XSquare size="12" /> </button>

                </div>)
        }
    }


    const { register, handleSubmit, errors } = useForm({
        mode: 'onSubmit',
        reValidateMode: 'onBlur'
    }
    );


    const clientEditHandler = (data) => {
        // console.log('here also');
        coreContext.editSelf(authContext.itemId, name, email, address, contact1, contact2, contactName, tag, loc, contact, description, pincode, localArea, stateId, districtId, category, countryId, status, otime, twitter, facebook, google, youtube, metaKeyWord, applyURL, webColor, bigHeader, sliderHeight, menuFontSize, leftHeaderSlug, rightHeaderSlug, googleAnalytics, sliderWidth, sliderSidePadding, showTopBar, showBelowBar, leftMenuSlug, leftMenuSpace);

    }

    const onImagesSelected = (pictures) => {
        setSelectedImages(pictures);
        //  console.log(pictures);
        if (pictures.length > 0) setUploadButtonDisabled(false); else setUploadButtonDisabled(true);
    }

    const onIconsSelected = (icons) => {
        setSelectedImages(icons);
        //  console.log(icons);
        if (icons.length > 0) setIconUploadButtonDisabled(false); else setIconUploadButtonDisabled(true);
    }

    const renderImageUploader = () => {
        if (hasImages) return <ImageUploader onImagesSelected={onImagesSelected} maxWidthOrHeight='4000' pictures={selectedImages} />
        else return '';
    }

    const renderIconUploader = () => {
        if (hasImages) return <ImageUploader onImagesSelected={onIconsSelected} maxWidthOrHeight='400' pictures={selectedImages} />
        else return '';
    }

    const onImagesUploadHandler = async () => {
        const count = selectedImages.length;
        let counter = 0;
        const preImagesCount = images.length;

        const imagesNowAllowed = maxImagesAllowed - preImagesCount;

        // if (count > imagesNowAllowed) { alert('You can now upload a maximum of ' + imagesNowAllowed + ' images.'); return }

        for await (const image of selectedImages) {
            ++counter;
            const formData = new FormData();
            formData.append('itemId', coreContext.client.id);
            formData.append('image', image, image.name);
            formData.append('userId', authContext.userId);
            formData.append('itype', 'logo');
            coreContext.uploadImage(formData, coreContext.client.id);

            if (counter === count) {
                setTimeout(() => {
                    setSelectedImages([]);
                    coreContext.fetchImages(coreContext.client.id);
                }, 2000);

                setTimeout(() => {
                    history.push('/');
                }, 5000);
            };
        }



    }

    const onIconUploadHandler = async () => {
        const count = selectedImages.length;
        let counter = 0;
        const preImagesCount = images.length;

        const imagesNowAllowed = maxImagesAllowed - preImagesCount;

        // if (count > imagesNowAllowed) { alert('You can now upload a maximum of ' + imagesNowAllowed + ' images.'); return }

        for await (const image of selectedImages) {
            ++counter;
            const formData = new FormData();
            formData.append('itemId', coreContext.client.id);
            formData.append('image', image, image.name);
            formData.append('userId', authContext.userId);
            formData.append('itype', 'icon');
            coreContext.uploadImage(formData, coreContext.client.id);

            if (counter === count) {
                setTimeout(() => {
                    setSelectedImages([]);
                    coreContext.fetchImages(coreContext.client.id);
                }, 2000);

                setTimeout(() => {
                    history.push('/');
                }, 5000);
            };
        }



    }

    const onMenuLefSlugChangedHandler = (e) => {
        setLeftMenuSlug(e.target.value);
    }
    const onMenuLefSpaceChangedHandler = (e) => {
        setLeftMenuSpace(e.target.value);
    }
    const onNameChangedHandler = (e) => {
        setName(e.target.value);
    }

    const onApplyURLChangedHandler = (e) => {
        setApplyURL(e.target.value);
    }

    const onApplyDefaultColorChangedHandler = () => {
        setWebColor('#00B16A');
        applyThemeColor('#00B16A', coreContext.client.id);
    }
    const onApplyColorChangedHandler = (color) => {
        setWebColor(color.hex);
        applyThemeColor(color.hex, coreContext.client.id)
    }

    const applyThemeColor = (color, itemId) => {
        coreContext.applyThemeColor(color, itemId);
    }

    const onMetaKeywordChangedHandler = (e) => {
        setMetaKeyWord(e.target.value);
    }

    const onOTimeChangedHandler = (e) => {
        setOtime(e.target.value);
    }
    const onTwitterChangedHandler = (e) => {
        setTwitter(e.target.value);
    }
    const onFacebookChangedHandler = (e) => {
        setFacebook(e.target.value);
    }
    const onGoogleChangedHandler = (e) => {
        setGoogle(e.target.value);
    }
    const onYoutubeChangedHandler = (e) => {
        setYoutube(e.target.value);
    }


    const onConNameChangedHandler = (e) => {
        setContactName(e.target.value);
    }

    const onPinChangedHandler = (e) => {
        setPincode(e.target.value);
    }

    const onAddressChangedHandler = (e) => {
        setAddress(e.target.value);
    }

    const onDescriptionChangedHandler = (e) => {
        setDescription(e.target.value);
    }

    const onLOCChangedHandler = (e) => {
        setLOC(e.target.value);
    }

    const onTagChangedHandler = (e) => {
        setTag(e.target.value);
    }

    const onEmailChangedHandler = (e) => {
        setEmail(e.target.value);
    }

    const onContact1Handler = (e) => {
        setContact1(e.target.value);
    }

    const onPasswordChangedHandler = (e) => {
        setPassword(e.target.value);
    }

    const onContactHandler = (e) => {
        setContact(e.target.value);
    }

    const onContact2Handler = (e) => {
        setContact2(e.target.value);
    }

    const onSliderHeightChangedHandler = (e) => {
        setSliderHeight(e.target.value);
    }

    const onMenuFontSizeChangedHandler = (e) => {
        setMenuFontSize(e.target.value);
    }

    const onLeftHeaderSpaceChangedHandler = (e) => {
        setLeftHeaderSlug(e.target.value);
    }

    const onRightHeaderSpaceChangedHandler = (e) => {
        setRightHeaderSlug(e.target.value);
    }

    const onGoogleAnalyticsChangedHandler = (e) => {
        setGoogleAnalytics(e.target.value);
    }

    const onCountryChangedHandler = (e) => {
        setCountryId(e.target.value);
        coreContext.fetchStates(e.target.value);
    }


    const onStateChangedHandler = (e) => {
        setStateId(e.target.value);
        coreContext.fetchDistricts(countryId, e.target.value);
    }

    const onDistrictChangedHandler = (e) => {
        setDistrictId(e.target.value);
    }

    const onBigHeaderChangedHandler = (e) => {
        setBigHeader(e.target.value);
    }

    const onSliderWidthChangedHandler = (e) => {
        setSliderWidth(e.target.value);
    }

    const onTopBarChangedHandler = (e) => {
        setShowTopBar(e.target.value);
    }

    const onBelowBarChangedHandler = (e) => {
        setShowBelowBar(e.target.value);
    }

    const onSliderPaddingChangedHandler = (e) => {
        setSliderSidePadding(e.target.value);
    }


    return (<div className='container'>
        <div className="row" >
            <div className="col-md-6">
                <div className="card">
                    <div className="card-title mx-auto"><h3><Badge variant="success">Kindly Complete Your Profile..</Badge></h3></div>
                    <div className="card-body">
                        <Form autoComplete='off' onSubmit={handleSubmit(clientEditHandler)} noValidate>
                            <Input label='Name' value={name} name='name' minLength={5} maxLength={55} required={true} register={register} errors={errors} elementType='text' placeholder='Enter Name' onChange={onNameChangedHandler} />

                            <Input label='Title' value={tag} name='tag' minLength={5} maxLength={555} required={false} register={register} errors={errors} elementType='textarea' placeholder='Enter Site Title . eg: "Siddhanta Technnology Services || The best School ERP provider || best Mobile App provider" ...Google use this title for SEO' onChange={onTagChangedHandler} />

                            <Input label='Meta Description' value={description} name='description' minLength={5} maxLength={555} required={false} register={register} errors={errors} elementType='textarea' placeholder='Enter Meta Description ... eg. "We are the best school in Muzaffarpur."  ...Google use these descriptions for SEO.' onChange={onDescriptionChangedHandler} />

                            <Input label='Meta Key words' value={metaKeyWord} name='metakeyword' minLength={5} maxLength={555} required={false} register={register} errors={errors} elementType='textarea' placeholder='Enter Meta key words ... eg. "best school, school, Muzaffarpur etc."  ...Google use these key words for SEO.' onChange={onMetaKeywordChangedHandler} />

                            <Input label='Opening Time' value={otime} name='otime' minLength={2} maxLength={55} required={false} register={register} errors={errors} elementType='text' placeholder='Enter School Opeining Time eg. 9:30 am to 1 pm' onChange={onOTimeChangedHandler} />

                            <Input label='Slider Height' value={sliderHeight} name='slider_height' maxLength={10} required={false} register={register} errors={errors} elementType='text' placeholder='Enter slider height eg. 500px' onChange={onSliderHeightChangedHandler} />

                            <Input label='Slider Width' name='slider_width' required={true} register={register} errors={errors} elementType='select' value={sliderWidth} options={sliderWidthOptions} onChange={onSliderWidthChangedHandler} />

                            <Input label='Slider Side Padding' value={sliderSidePadding} name='slider_side_padding' maxLength={10} required={false} register={register} errors={errors} elementType='text' placeholder='Enter slider side padding eg. 55px' onChange={onSliderPaddingChangedHandler} />

                            <Input label='Menu Left Space' name='menu_left_space'
                                register={register} required={false} errors={errors} elementType='select' value={leftMenuSpace} options={menuLeftSpaceOptions} onChange={onMenuLefSpaceChangedHandler} />

                            {leftMenuSpace ? <Input label='Menu Left Slug' value={leftMenuSlug} name='left_header_slug' maxLength={100} required={false} register={register} errors={errors} elementType='text' placeholder='Copy/ paste left menu slug' onChange={onMenuLefSlugChangedHandler} />
                                : null}

                            <Input label='Menu Font Size' value={menuFontSize} name='menu_font_size' maxLength={10} required={false} register={register} errors={errors} elementType='text' placeholder='Enter menu font size eg. 20px' onChange={onMenuFontSizeChangedHandler} />

                            <Input label='Big Header' name='big_header' required={true} register={register} errors={errors} elementType='select' value={bigHeader} options={bigHeaderOptions} onChange={onBigHeaderChangedHandler} />


                            {bigHeader === 'yes' ? <Input label='Header Left Slug' value={leftHeaderSlug} name='left_header_slug' maxLength={100} required={false} register={register} errors={errors} elementType='text' placeholder='Copy/ paste left header slug' onChange={onLeftHeaderSpaceChangedHandler} />
                                : null}

                            {bigHeader === 'yes' ? <Input label='Header Right Slug' value={rightHeaderSlug} name='right_header_slug' maxLength={100} required={false} register={register} errors={errors} elementType='text' placeholder='Copy/ paste right header slug' onChange={onRightHeaderSpaceChangedHandler} />
                                : null}

                            <Input label='Show Top Bar' name='top_bar' required={true} register={register} errors={errors} elementType='select' value={showTopBar} options={topBarOptions} onChange={onTopBarChangedHandler} />

                            <Input label='Show Below Bar' name='below_bar' required={true} register={register} errors={errors} elementType='select' value={showBelowBar} options={belowBarOptions} onChange={onBelowBarChangedHandler} />

                            <Input label='Twitter Link' value={twitter} name='twitter' minLength={5} maxLength={255} required={false} register={register} errors={errors} elementType='textarea' placeholder='Enter Twitter Link' onChange={onTwitterChangedHandler} />

                            <Input label='Facebook Link' value={facebook} name='facebook' minLength={5} maxLength={255} required={false} register={register} errors={errors} elementType='textarea' placeholder='Enter Facebook Link' onChange={onFacebookChangedHandler} />

                            <Input label='Google+ Link' value={google} name='google' minLength={5} maxLength={255} required={false} register={register} errors={errors} elementType='textarea' placeholder='Enter Google+ Link' onChange={onGoogleChangedHandler} />

                            <Input label='Youtube Link' value={youtube} name='youtube' minLength={5} maxLength={255} required={false} register={register} errors={errors} elementType='textarea' placeholder='Enter Youtube Chhanel Link' onChange={onYoutubeChangedHandler} />

                            <Input label='Apply Button URL' value={applyURL} name='applyurl' minLength={5} maxLength={255} required={false} register={register} errors={errors} elementType='textarea' placeholder='Enter Apply Now Button Url' onChange={onApplyURLChangedHandler} />

                            {/* <Input label='Apply Website Color' value={webColor} name='webcolor' minLength={2} maxLength={255} required={false} register={register} errors={errors} elementType='color' placeholder='Choose Theme Color' onChange={onApplyColorChangedHandler} /> */}

                            <div className="row"><div className="col-md-9">
                                <Form.Label><h6><Badge variant='dark'>Choose Website Theme Color</Badge></h6></Form.Label>
                                <CirclePicker
                                    color={webColor}
                                    onChangeComplete={onApplyColorChangedHandler}
                                />
                            </div>
                                <div className="col-md-3"> <Input elementType='checkbox' value="Apply Default Theme Color" onChange={onApplyDefaultColorChangedHandler} /></div>
                            </div> <br />


                            <Input label='LOC' name='log' value={loc} minLength={2} maxLength={555} required={false} register={register} errors={errors} elementType='textarea' placeholder='Google Map Embed Code  from map.google.com' onChange={onLOCChangedHandler} />

                            <Input label='Google Analytics Code' name='google_analytics' value={googleAnalytics} minLength={2} maxLength={2000} required={false} register={register} errors={errors} elementType='textarea' placeholder='Google Analytics Code' onChange={onGoogleAnalyticsChangedHandler} />

                            <Input label='Contact Person Name' name='contactName' value={contactName} minLength={2} maxLength={55} required={false} register={register} errors={errors} elementType='text' placeholder='Contact Person Name' onChange={onConNameChangedHandler} />

                            <Input label='Contact' value={contact} name='contact' minLength={10} maxLength={20} required={true} register={register} errors={errors} elementType='text' placeholder='Enter Contact No.' onChange={onContactHandler} />

                            <Input label='Mobile-I' name='contact1' value={contact1} minLength={10} maxLength={10} required={true} register={register} errors={errors} elementType='text' placeholder='Enter Contact No 1.' onChange={onContact1Handler} />

                            <Input label='Mobile-II' value={contact2} name='contact2' minLength={10} maxLength={10} required={false} register={register} errors={errors} elementType='text' placeholder='Enter Contact No 2.' onChange={onContact2Handler} />

                            <Input label='Email' name='email' value={email} required={true} register={register} errors={errors} elementType='text' readOnly={true} minLength={5} maxLength={55} pattern={/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/} placeholder='Enter Email' onChange={onEmailChangedHandler} />

                            {/* <Input label='Reset Password' required={false} register={register} errors={errors} name='password' minLength={5} maxLength={55} elementType='password' placeholder='Reset Password' onChange={onPasswordChangedHandler} /> */}

                            <Input label='Address' value={address} name='address' minLength={5} maxLength={155} required={false} register={register} errors={errors} elementType='textarea' placeholder='Enter Complete Address to be shown on Website' onChange={onAddressChangedHandler} />

                            <Input label='Pincode' value={pincode} name='pincode' minLength={6} maxLength={6} required={false} register={register} errors={errors} elementType='text' placeholder='Enter Pincode' onChange={onPinChangedHandler} />

                            <Input label='Country' name='country' required={true} register={register} errors={errors} elementType='select' value={countryId} options={coreContext.countryOptions} onChange={onCountryChangedHandler} />

                            <Input label='State' name='state' required={true} register={register} errors={errors} elementType='select' value={stateId} options={coreContext.stateOptions} onChange={onStateChangedHandler} />

                            <Input label='District' name='district' required={true} register={register} errors={errors} elementType='select' value={districtId} options={coreContext.districtOptions} onChange={onDistrictChangedHandler} />

                            <Input elementType='button' variant='primary' value="Update" /> &nbsp;&nbsp; {coreContext.renderLoader()}
                            <Input variant='danger' label={coreContext.message} elementType='label' />
                        </Form>
                    </div>
                </div>
            </div>
            <div className="col-md-6" style={{ maxHeight: '600px', overflowY: 'auto', position: 'sticky', top: '50px' }}>
                <div className="card">

                    <div className="card-body">
                        {renderImageUploader()}
                        <Input value='Upload Site Logo' onClick={onImagesUploadHandler} disabled={uploadButtonDisabled} elementType='button' variant='primary' />&nbsp;&nbsp; {coreContext.renderLoader()}
                        <Input variant='danger' label={coreContext.message} elementType='label' />
                    </div>

                </div>
                <div className="card">
                    <div className="card-header">Logo</div>
                    <div className="card-body">
                        <div className="row">
                            {renderImages()}
                        </div>
                    </div>
                </div>

                <div className="card">

                    <div className="card-body">
                        {renderIconUploader()}
                        <Input value='Upload Site Icon (Title Bar Icon)' onClick={onIconUploadHandler} disabled={iconUploadButtonDisabled} elementType='button' variant='primary' />&nbsp;&nbsp; {coreContext.renderLoader()}
                        <Input variant='danger' label={coreContext.message} elementType='label' />
                    </div>
                    <div className="card">
                        <div className="card-header">Site Icon</div>
                        <div className="card-body">
                            <div className="row">
                                {renderIcons()}
                            </div>
                        </div>
                    </div> <br /><br />
                </div>
            </div>
        </div> </div>);
}


export { Self };