import React, { useState, useContext, useEffect } from "react";
import { Form, Badge } from 'react-bootstrap';
import { Link } from "react-router-dom";
import Axios from 'axios';
import { useForm } from "react-hook-form";
import { useHistory } from 'react-router-dom';
import Input from './common/Input';
import { AuthContext } from '../context/auth-context';
import { CoreContext } from '../context/core-context';


const AddUser = (props) => {

    const authContext = useContext(AuthContext);
    const coreContext = useContext(CoreContext);

    const history = useHistory();

    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [contact1, setContact1] = useState('');
    const [contact2, setContact2] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [role, setRole] = useState('');
    const [nationality, setNationality] = useState('Indian');
    const [sCategory, setSCategory] = useState('');
    const [organization, setOrganization] = useState('');
    const [department, setDepartment] = useState('');
    const [designation, setDesignation] = useState('');
    const [accomodation, setAccomodation] = useState('');
    const [presenting, setPresenting] = useState('');
    const [accompanying, setAccompanying] = useState('');

    const [nationalityOptions] = useState([{ name: 'Indian', value: 'Indian' }, { name: 'Others', value: 'Others' }]);
    const [yesNoOptions] = useState([{ name: 'Select', value: '' },{ name: 'Yes', value: 'yes' }, { name: 'No', value: 'no' }]);

    //{ name: 'Not Presenting', value: 'no' },
    const [presentingOptions] = useState([{ name: 'Select One', value: '' }, { name: 'Invited Talk', value: 'Invited Talk' },{ name: 'Contributed Talk', value: 'Contributed Talk' },{ name: 'Poster Presentation', value: 'Poster Presentation' },{ name: 'Only Participating', value: 'Only Participating' },]);

    const [accompanyingOptions] = useState([{ name: 'None', value: 'no' },{ name: 'One', value: '1' },{ name: 'Two', value: '2' },{ name: 'Three', value: '3' },{ name: 'Four', value: '4' },{ name: 'Five', value: '5' },{ name: 'Six', value: '6' },{ name: 'Seven', value: '7' },{ name: 'Eight', value: '8' },{ name: 'Nine', value: '9' },{ name: 'Ten', value: '10' },]);

    const [sRoleOptions] = useState([{ name: 'Select One', value: '' },{ name: 'Faculty from BHU', value: 'Faculty' }, { name: 'Student from BHU', value: 'Student' }, { name: 'Post Docs from BHU', value: 'Post Docs' },{ name: 'Faculty from Other Institute', value: 'Faculty from Other Institute' }, { name: 'Student  from Other Institute', value: 'Student from Other Institute' }, { name: 'Post Docs from Other Institute', value: 'Post Docs from Other Institute' },{ name: 'Overseas Faculty', value: 'Overseas Faculty' }, { name: 'Overseas Student', value: 'Overseas Student' }, { name: 'Overseas Post Docs', value: 'Oversease Post Docs' }, { name: 'Industrial Participants', value: 'Industrial Participants' }]);


    const { register, handleSubmit, errors } = useForm({
        mode: 'onSubmit',
        reValidateMode: 'onBlur',
    });
    // const onSubmit = data => console.log(data);

    const roleOptions = () => {
        coreContext.fetchRoleOptions();
    }

    useEffect(roleOptions, []);

    const setPathName = ()=>{
        coreContext.setPathName(props.location.pathname);

        const website = authContext.ownerId ? authContext.ownerId : localStorage.getItem('app_ownerId')?localStorage.getItem('app_ownerId'):  props.match.params.website;
        if(website) {
            authContext.setOwnerId(website); // fetch logo dynamically here
            localStorage.setItem('app_ownerId', website);
            } else window.location='http://ambt2023.org/registration';
    }

    useEffect(setPathName, []);

    const signUpHandler = (data) => {
        if(authContext.ownerId)
        Axios.post('/sign-up', { email, password, name, userId:authContext.ownerId, itemId: authContext.ownerId, contact1, contact2, nationality, sCategory, organization, designation, accomodation, presenting, accompanying, department }).then(response => {
            authContext.setShowLoader(false);
            // console.log('after signup', response.data);
            const value = response.data.message;
            authContext.setMessage(value);
          //  createPages(response.data.data.itemId, response.data.data.userId);
          
          authContext.login(email, password, '/');

        }).catch(err => {
            const value = err.response.data.data;
            authContext.setMessage(value[0].msg);
            authContext.setShowLoader(false);
        })


     else  alert('Error! Branch could not indentified !!');

    }




    const onEmailChangedHandler = (e) => {
        setEmail(e.target.value);
    }

    const onContact1Handler = (e) => {
        setContact1(e.target.value);
    }

    const onContact2Handler = (e) => {
        setContact2(e.target.value);
    }

    const onRoleChangedHandler = (e) => {
        setRole(e.target.value);
    }

    const onNameChangedHandler = (e) => {
        setName(e.target.value);
    }

    const onPasswordChangedHandler = (e) => {
        setPassword(e.target.value);
        verifyPassword(e.target.value, confirmPassword);
    }

    const onConfirmPasswordChangedHandler = (e) => {
        setConfirmPassword(e.target.value);
        verifyPassword(password,e.target.value, );
    }

    const verifyPassword = (p, cp) => {
if(p !== cp)
    authContext.setMessage('Password and confirm password do not match..');
    else
    authContext.setMessage('');


    }


    return (
        <div className='container'>
            <div className="row">
                <div className="col-md-2"></div>
                <div className="col-md-8">
                    <div className="card">
                        <div className="card-title mx-auto"><h3><Badge variant="success">Regiser Yourself</Badge></h3></div>
                        <div className="card-body">
                            <Form autoComplete='off' onSubmit={handleSubmit(signUpHandler)} noValidate>
                                <Input label='Name' name='name' minLength={5} maxLength={55} required={true} register={register} errors={errors} elementType='text' placeholder='Enter Name' onChange={onNameChangedHandler} />

                                <Input label='Designation' name='designation' minLength={2} maxLength={55} required={true} register={register} errors={errors} elementType='text' placeholder='Enter designation' onChange={(e)=> setDesignation(e.target.value)} />

                                <Input label='Email' name='email' required={true} register={register} errors={errors} elementType='email' minLength={5} maxLength={55} pattern={/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/} placeholder='Enter Email' onChange={onEmailChangedHandler} />

                                <Input label='Phone' name='contact1' minLength={6} maxLength={15} required={true} register={register} errors={errors} elementType='text' placeholder='Enter Phone no.' onChange={onContact1Handler} />

                                
                                <Input label='Nationality' name='nationality' required={true} register={register} errors={errors} elementType='select' options={nationalityOptions} onChange={(e) => setNationality(e.target.value)} />

                                <Input label='Department' name='department' minLength={2} maxLength={55} required={true} register={register} errors={errors} elementType='text' placeholder='Enter your department name' onChange={(e)=> setDepartment(e.target.value)} />

                                <Input label='Institute / Organization' name='organization' minLength={2} maxLength={55} required={true} register={register} errors={errors} elementType='text' placeholder='Enter Institute / Organization name' onChange={(e)=> setOrganization(e.target.value)} />

                                <Input label='Password' required={true} register={register} errors={errors} name='password' minLength={5} maxLength={55} elementType='password' placeholder='Enter Password' onChange={onPasswordChangedHandler} />

                                <Input label='Confirm Password' required={true} register={register} errors={errors} name='copassword' minLength={5} maxLength={55} elementType='password' placeholder='Enter Confirm Password' onChange={onConfirmPasswordChangedHandler} />

                                <Input label='Presentation Mode' name='presenting' required={true} register={register} errors={errors} elementType='select' options={presentingOptions} onChange={(e)=> setPresenting(e.target.value)} />
  
                                <Input label='Category' name='category' required={true} register={register} errors={errors} elementType='select' options={sRoleOptions} onChange={(e) => setSCategory(e.target.value)} />
                               
                                <Input label='Accomodation Required' name='accomodation' required={true} register={register} errors={errors} elementType='select' options={yesNoOptions} onChange={(e)=> setAccomodation(e.target.value)} />
                              
                                <Input label='Accompanying Person Count' name='accompanying' required={true} register={register} errors={errors} elementType='select' options={accompanyingOptions} onChange={(e)=> setAccompanying(e.target.value)} />


                              {password && confirmPassword && confirmPassword === password ?  <Input elementType='button' variant='primary' />: ''} &nbsp;&nbsp; {authContext.ren}
                                <Input variant='danger' label={authContext.message} elementType='label' /> &nbsp;&nbsp;  
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

}



export { AddUser };