/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect } from 'react';
import { CoreContext } from '../context/core-context';
import { AuthContext } from '../context/auth-context';
import { Link } from "react-router-dom";
const CoachingListAdmin = () => {

    const coreContext = useContext(CoreContext);
    const authContext = useContext(AuthContext);

    const fetchClient = () => {

        coreContext.fetchSelf(authContext.itemId);
    }

    useEffect(fetchClient, []);

    const certificateStatus = () =>{
        if(coreContext.client.profile_image){
            return   <div className='row mt-4'>
            <div className='col-md-6'>
                <h3 className='card-title'>Admin Document for You</h3>
        <div className='card card-body'><div className="col-md-6"> <a rel="noopener noreferrer" target="_blank" href={`http://${coreContext.client.profile_image}`}><img style={{ padding: 5 }} className="img-fluid" src={'https://node-websites.siddhantait.com/images/pdfimage.png'} alt='Certificate' /></a></div> </div></div></div>
        } 
    }

    const bookingStatus = () =>{
        if(coreContext.client.smst_payment_done === 'no' && coreContext.client.smst_abstract_accepted === 'yes'){
            return <div>
                <h6><Link className="text-danger" to="/upload-receipt">Register for the Event (Upload Payment Slip)</Link></h6>
            </div>;
        } else if(coreContext.client.smst_booking_confirmed === 'no' && coreContext.client.smst_payment_done === 'yes'){
            return <div>
                <h6>We are in receipt of your payment slip, we will shortly register you for the event and confirm.<Link className="text-danger" to="/upload-receipt">View receipt</Link></h6>
            </div>;
        } else if(coreContext.client.smst_booking_confirmed === 'yes' && coreContext.client.smst_payment_done === 'yes'){ return <div>
        <h6 className="text-success">Congratulations, You have successfully registered yourself for the conference. <a className="text-danger" href="https://ambt2023.org">Click here</a> to view the conference details.</h6>
    </div>;} return <div></div>;
    }

    const abstractStatus = () =>{
        if(coreContext.client.smst_presenting === 'no'){
            return <div>
                <h6><a className="text-danger" href="#">You have not registered yourself as a presenter.</a></h6>
            </div>;
        } else if(coreContext.client.smst_abstract_uploaded === 'no'){
            return <div>
                <h6><Link className="text-danger" to="/upload-abstract">Upload your abstract</Link></h6>
            </div>;
        }else if(coreContext.client.smst_abstract_accepted === 'no'){
            return <div>
                 <h6>We are in receipt of your abstract, we will shortly let you know and confirm.<Link className="text-danger" to="/upload-abstract">View your abstract</Link></h6>
            </div>;
        } else return <div>
        <h6 className="text-success">Congratulations, your abstract has been accepted by our team. <a className="text-danger" href="https://ambt2023.org">Click here</a> to view the conference details.</h6>
    </div>;
    }

    return (
       <div className='card'>
        <div className='row'>
            <div className='col-md-6'>
        <div className='card card-body'>
           <h5 className='card-title'>Booking Status</h5>
{bookingStatus()}
        </div>
        </div>
            <div className='col-md-6'>
            <div className='card card-body'>
            <h5 className='card-title'>Abstract Status</h5>
            {abstractStatus()}
        </div>
            </div>
        </div>
      
            {certificateStatus()}
           
       </div>
    );

}

export { CoachingListAdmin };