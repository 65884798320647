import React, { useContext, useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { Form, Badge } from 'react-bootstrap';
import { AuthContext } from '../context/auth-context';
import Input from './common/Input';
import { CoreContext } from "../context/core-context";





const Login = (props) => {
    const authContext = useContext(AuthContext);
    const coreContext = useContext(CoreContext);

    const setPathName = ()=>{
        coreContext.setPathName(props.location.pathname);
        const website = authContext.ownerId ? authContext.ownerId : localStorage.getItem('app_ownerId')?localStorage.getItem('app_ownerId'):  props.match.params.website;
       if(website) {
       authContext.setOwnerId(website); // fetch logo dynamically here
       localStorage.setItem('app_ownerId', website);
       } else window.location='http://ambt2023.org/registration';
    }

    const resetMessage = () =>{
        authContext.setMessage('');
    }

    useEffect(setPathName, []);
    useEffect(resetMessage, []);

    const login = () => {
        console.log('prakash3',authContext);
        if(authContext.ownerId)
        authContext.login(email, password, props.match.url);
        else alert('Error! Branch could not indentified !!');
    }

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const onEmailChangedHandler = (e) => {
        setEmail(e.target.value);
    }

    const onPasswordChangedHandler = (e) => {
        setPassword(e.target.value);
    }
    const { register, handleSubmit, errors } = useForm({
        mode: 'onSubmit',
        reValidateMode: 'onBlur',
    });


    return (
        <div className='container'>
            <div className="row">
                <div className="col-md-2"></div>
                <div className="col-md-8">
                    <div className="card">
                        <div className="card-title mx-auto"><h3><Badge variant="success">Sign In</Badge></h3></div>
                        <div className="card-body">
                            <Form autoComplete='off' onSubmit={handleSubmit(login)} noValidate>
                                <Input label='Email' elementType='email' minLength={5} maxLength={55} placeholder='Enter Email' onChange={onEmailChangedHandler} name='email' required={true} register={register} errors={errors} pattern={/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/} />
                                <Input label='Password' elementType='password' placeholder='Enter Password' onChange={onPasswordChangedHandler} required={true} minLength={5} maxLength={55} register={register} errors={errors} name='password' />
                                <Input elementType='button' variant='primary' /> &nbsp;&nbsp; {authContext.renderLoader()}
                                <Input variant='danger' label={authContext.message} elementType='label' />
                               
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

}






export { Login };