/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState, useContext, useEffect } from 'react';
import Input from './common/Input';
import { Badge } from 'react-bootstrap';
import { CoreContext } from '../context/core-context';
import { AuthContext } from '../context/auth-context';
import { useForm } from "react-hook-form";
import { useHistory } from 'react-router-dom';

const UploadAbstractDocs = () => {

    const coreContext = useContext(CoreContext);
    const authContext = useContext(AuthContext);
    const [selectedImages, setSelectedImages] = useState([]);
    const [selectedFile, setSelectedFile] = useState(null);
    const [uploadButtonDisabled, setUploadButtonDisabled] = useState(true);

    const [image, setImage] = useState('');

    const history = useHistory();
    const fetchClient = () => {
        coreContext.fetchSelfEnrollToday(authContext.itemId);
    }


    useEffect(fetchClient, []);

    const populateClient = () => {
      
        setImage(coreContext.client.register_now_image);
    }

    useEffect(populateClient, [coreContext.client]);

    const renderImages = () => {
        if (image){
            if (image.includes('.pdf'))
            return <div className="col-md-6"><a rel="noopener noreferrer" target="_blank" href={`https://${image}`}><img style={{ padding: 5 }} className="img-fluid" src={'https://node-websites.siddhantait.com/images/pdfimage.png'} alt='Abstract Copy' /></a></div>
            else
            return <div className="col-md-6"><a rel="noopener noreferrer" target="_blank" href={`https://${image}`}><img style={{ padding: 5 }} className="img-fluid" src={'https://node-websites.siddhantait.com/images/word-file.jpg'} alt='Abstract Copy' /></a></div>
        }
    }

  

  

    const onFileChange = (e) =>{
        setSelectedFile(e.target.files[0]);
        if (e.target.files[0].name) setUploadButtonDisabled(false); else setUploadButtonDisabled(true);
    }

    const onFileUpload = () =>{

        const count = selectedImages.length;
        if (count > 1) { alert('Only one document should be uploaded for this section !'); return; }

           // Create an object of formData
      const formData = new FormData();

      formData.append('itemId', coreContext.client.id);
      formData.append('image', selectedFile,  selectedFile.name);
      formData.append('userId', authContext.userId);
      coreContext.uploadRegisterNowImage(formData, coreContext.client.id);

      setTimeout(() => {
        setSelectedImages([]);
        fetchClient();
    }, 2000);
    setTimeout(() => {
        history.push('/');
    }, 5000);

    }

    return (<div className='container-fluid'>

        <div className="card">
            <div className="card-title mx-auto"><h3><Badge variant="success">Upload Abstract</Badge></h3></div>
            <div className="card-title mx-auto"></div>
            {coreContext.client.smst_abstract_accepted === 'no' ?
            <div className="card-body">
                    <div className="row">
                        <div className="col-md-3"></div>
                        <div className="col-md-6">
                        <input type="file" onChange={onFileChange} className='form-control mb-2' />

<Input value='Upload Abstract File' onClick={onFileUpload} disabled={uploadButtonDisabled} elementType='button' variant='primary' />&nbsp;&nbsp; {coreContext.renderLoader()}
    <Input variant='danger' label={coreContext.message} elementType='label' />
                        </div>
                        <div className="col-md-3"></div>
                    </div>
         
            </div>
: ''}
          
                <div className="card-body">
                <div className="card-title mx-auto"><h3><Badge variant="success">Abstract Copy</Badge></h3></div>
                <div className="row">
                    {renderImages()}
                </div>
            </div>
    
        </div>
    </div>
    )
}

export { UploadAbstractDocs }